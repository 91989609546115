import React, { useEffect, useState, useRef, useCallback } from "react";
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";
import { map, debounce, find, filter, upperCase, get, forEach } from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
	ApiKey,
	ApiUrl,
	WebUrl,
	SessionKey,
	AlertTypes,
	_MAIN_WALLET_ID,
	//PhoneNumberPrefix,
	DEFAULT_REFERRAL_CODE,
} from "../../util/Constant";
import {
	Alert,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Modal,
	ModalBody,
	Spinner,
	UncontrolledDropdown,
} from "reactstrap";
import PinInput from "react-pin-input";
import Input from "../../components/custom/Input";
import { useForm } from "react-hook-form";
import {
	bindMember,
	setBusy,
	setDepositModal,
	setIdle,
	setLoginModal,
	setRegisterModal,
	showMessage,
	showResponseMessage,
	bindCompanyIcon,
} from "../../redux/AppAction.js";
import SecureLS from "secure-ls";
import { performLoginReturnMessage } from "../../redux/AuthAction.js";
import ApiEngine from "../../util/ApiEngine.js";
import {
	stringIsNullOrEmpty,
	numberWithCurrencyFormat,
	createFormBody,
	createMultiPartFormBody,
} from "../../util/Util";
import { setOpenMenu } from "redux/AppAction";
import Deposit from "../../pages/template_1/transaction/Deposit";
import WithdrawalMethods from "../../pages/template_1/transaction/WithdrawalMethods";
import i18n from "localization/i18n";
import Cookies from "universal-cookie";
import { saveLanguageId } from "redux/LanguageAction";
import PlaceholderLoading from "react-placeholder-loading";
import whatsappimg from "../../pages/template_1/transaction/WithdrawalMethods";

const Header = (props) => {
	var { t } = useTranslation();
	var _history = useHistory();
	var _dispatch = useDispatch();
	const location = useLocation();

	var ls = new SecureLS();
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const {
		register: loginRegister,
		handleSubmit: loginHandleSubmit,
		errors: loginErrors,
	} = useForm();
	const {
		register: forgotPasswordRegister,
		handleSubmit: forgotPasswordHandleSubmit,
		errors: forgotPasswordErrors,
		watch: forgotPasswordWatch,
		setValue: forgotPasswordSetValue,
		unregister: forgotPasswordUnregister,
	} = useForm();
	const {
		register: tacVerifyRegister,
		handleSubmit: tacVerifyHandleSubmit,
		errors: tacVerifyErrors,
		watch: tacVerifyWatch,
		setValue: tacVerifySetValue,
		unregister: tacVerifyUnregister,
	} = useForm();
	const {
		register: ResetPasswordRegister,
		handleSubmit: ResetPasswordHandleSubmit,
		errors: ResetPasswordErrors,
		watch: ResetPasswordWatch,
		setValue: ResetPasswordSetValue,
		unregister: ResetPasswordUnregister,
	} = useForm();

	const [showCodeError, setShowCodeError] = useState(false);
	const {
		register,
		handleSubmit,
		errors,
		clearErrors,
		setError,
		setValue,
		getValues,
		watch,
		control,
		trigger,
		phoneNumberChk,
	} = useForm();
	const { handleSubmit: PasswordUpdatedHandleSubmit } = useForm();

	const handleReferralCode = (code) => {
		setReferralCode(code);
	};

	const PhoneNumberPrefix = useSelector((state) => state.appState.dialingCodes);
	const [showModalLogin, setShowModalLogin] = useState(false);
	const registerModal = useSelector((state) => state.appState.registerModal);
	const [hideLoginPassword, setHideLoginPassword] = useState(true);
	const [registerSteps, setRegisterSteps] = useState(1);
	const openMenu = useSelector((state) => state.appState.openMenu);
	const [activeLanguage, setActiveLanguage] = useState(
		localStorage.getItem(SessionKey._LANGUAGE) || "EN"
	);
	const [currentWalletBalance, setCurrentWalletBalance] = useState(0);
	var _userData = useSelector((state) => state.authState.userData);
	var { heartBeat } = useSelector((state) => state.authState);
	const [referralCode, setReferralCode] = useState("");
	const [formattedPhone, setFormattedPhone] = useState("");
	const [phoneNumberError, setPhoneNumberError] = useState(false);
	const [_username, _setUsername] = useState("");
	const [isUsernameAvailable, setIsUsernameAvailable] = useState();
	const [isDigit, setIsDigit] = useState(false);
	const [selectedPhonePrefix, setSelectedPhonePrefix] = useState("");

	const [phoneNumber, setPhoneNumber] = useState("");
	const [enteredPinNumber, setEnteredPinNumber] = useState("");
	const [enteredPinNumberConfirm, setEnteredPinNumberConfirm] = useState("");
	const [pinNumberConfirmFocused, setPinNumberConfirmFocused] = useState(0);
	const [pinNumber, setPinNumber] = useState("");
	const _TAC_VALID_TIMER = 60000;
	const _SECONDS_IN_MS = 1000;

	const _TAC_TIMEOUT_IN_MINUTES = 1;
	const [TACtimer, setTACtimer] = useState(setTimeout(() => {}, 60000));
	const [countdownPeriod, setCountdownPeriod] = useState(0);
	const [TACcountdownPeriod, setTACCountdownPeriod] = useState(0);
	const [TACsent, setTACsent] = useState(true);
	const [TACtimedOut, setTACtimedOut] = useState(true);
	const [canSendTAC, setCanSendTAC] = useState(true);
	const [TACBtnAble, setTACBtnAble] = useState(false);
	const [attemp, setAttemp] = useState(1);

	const elePin = useRef(null);
	const [tempPinNumberFocused, setTempPinNumberFocused] = useState(0);
	const [tempPinNumber, setTempPinNumber] = useState();
	const OTP_VIA_SMS = 1;
	const OTP_VIA_EMAIL = 2;
	const OTP_VIA_WHATSAPP = 3;
	const [isLiveChatOpen, setIsLiveChatOpen] = useState(false);
	const [registerStepsViaEmail, setRegisterStepsViaEmail] = useState(false);
	const [registerStepsThreeFirst, setRegisterStepsThreeFirst] = useState(false);
	const [_email, _setEmail] = useState("");
	const [_emailText, _setEmailText] = useState("");
	const [isEmailAvailable, setIsEmailAvailable] = useState();

	const [password, setPassword] = useState("");
	const [referralCodeString, setReferralCodeString] = useState("");

	const _MIN_PASSWORD_LENGTH = 9;

	const [forgotPassword, setForgotPassword] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isForgetPassword, setIsForgetPassword] = useState(false);
	const [isLogin, setIsLogin] = useState(true);
	const [verifyPhoneTAC, setVerifyPhoneTAC] = useState(false);
	const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
	const verifyState = {
		button: 1,
	};
	const [isUpdatePassword, setIsUpdatePassword] = useState(false);
	const [tempPhoneNum, setTempPhoneNum] = useState("");
	const [countdownTAC, setCountdownTAC] = useState(false);
	const [username, setUsername] = useState("");

	const channels = useSelector((state) => state.appState.channels);

	const [selectedChannel, setSelectedChannel] = useState();
	const [toggleModal, setToggleModal] = useState();
	const [isLoginLoading, setIsLoginLoading] = useState(false);
	const [isTACLoading, setIsTACLoading] = useState(false);
	const [isRegisterLoading, setIsRegisterLoading] = useState(false);
	const [disableRegister, setDisableRegister] = useState(false);
	const [disableViaEmail, setDisableViaEmail] = useState(false);
	const [viaEmailLoading, setViaEmailLoading] = useState(false);

	const [pinLoading, setPinLoading] = useState(false);
	const [timerPeriod, setTimerPeriod] = useState(null);
	const [pinInputDisabled, setPinInputDisabled] = useState(false);
	const activeLanguageCode = useSelector(
		(state) => state.languageState.languageId
	);
	const [tabActive, setTabActive] = useState();
	const [disabledRefrerral, setDisabledRefrerral] = useState(false);
	const walletsDetails = useSelector((state) => state.appState.walletsDetails);
	const walletBalance = useSelector((state) => state.appState.walletBalance);
	const companyIcons = useSelector((state) => state.appState.companyIcons);
	const [sideMenuList, setSideMenuList] = useState();
	const [logo, setLogo] = useState();
	const [smallLogo, setSmallLogo] = useState();
	const [alertIcon, setAlertIcon] = useState();
	const [profileIcon, setProfileIcon] = useState();
	const [loginBanner, setLoginBanner] = useState();
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const [openMobileSideMenu, setOpenMobileSideMenu] = useState(false);
	const [balanceRefresh, setBalanceRefresh] = useState(false);
	const [chatLoading, setChatLoading] = useState(true);

	var companyLanguages = useSelector((state) => state.appState.languages);
	const [languageSelected, setLanguageSelected] = useState(
		companyLanguages.find(
			(x) => x.code == localStorage.getItem(SessionKey._LANGUAGE)
		)
	);

	var registerChannel = useSelector((state) => state.appState.chatSphere);
	var totalPromoClaimable = useSelector((state) =>
		state.authState.heartBeat ? state.authState.userData.totalPromoClaimable : 1
	);
	var unreadLivechat = useSelector((state) =>
		state.authState.heartBeat ? state.authState.userData.totalUnreadLivechat : 1
	);
	const customerServiceUrl = "/livechat";
	const [languageDetected, setLanguageDetected] = useState(false);

	const membershipEnabled = useSelector(
		(state) => state.appState.membershipEnabled
	);
	const memberData = useSelector((state) => state.appState.member);

	useEffect(() => {
		if (companyIcons.length > 0) {
			getCompanyIcon();
		}
	}, [companyIcons]);

	useEffect(() => {
		if (!heartBeat) {
			setCurrentWalletBalance(0);
		} else {
			bindMember(_dispatch);
		}
		const fetchData = async () => {
			if (_userData["walletBalance"]) {
				setCurrentWalletBalance(_userData["walletBalance"]);
			}
		};
		fetchData();
	}, [heartBeat, localStorage.getItem(SessionKey._TEMP_GUID)]);

	useEffect(() => {
		if (location.state) {
			setTabActive(location.state.reference);
		} else {
			if (location.pathname == "/") {
				setTabActive("Home");
			} else {
				setTabActive();
			}
		}
	}, [location]);

	/*useEffect(() => {
		if (heartBeat) {
			bindMember(_dispatch);
		}
	}, [activeLanguageCode]);*/

	useEffect(() => {
		if (!registerModal) {
			setRegisterSteps(1);
			setAttemp(1);
			_setEmail("");
			_setEmailText("");
			clearTimeout(timerPeriod);
			clearTimeout(TACtimer);
		}
	}, [registerModal]);

	useEffect(() => {
		if (countdownPeriod > 0) {
			setTimerPeriod(
				setTimeout(() => {
					let tempPeriod = countdownPeriod - 1;
					setCountdownPeriod(tempPeriod);
				}, _SECONDS_IN_MS)
			);
		}
	}, [countdownPeriod]);

	useEffect(() => {
		if (TACcountdownPeriod > 0) {
			setTACtimer(
				setTimeout(() => {
					let tempPeriod = TACcountdownPeriod - 1;
					setTACCountdownPeriod(tempPeriod);
				}, _SECONDS_IN_MS)
			);
		}
	}, [TACcountdownPeriod]);

	useEffect(() => {
		// Set default language
		if (companyLanguages?.length) {
			const tempLangCode = companyLanguages[0]?.code;
			const storedLanguage = localStorage.getItem(SessionKey._LANGUAGE);
			const tempLang = companyLanguages.find((x) => x.isDefault === true);

			// console.log('storedLanguage', storedLanguage)
			// console.log('tempLang', tempLang)

			//console.log('Set default lang')
			if (storedLanguage && storedLanguage !== "undefined") {
				onInitLanguage(storedLanguage);
			} else if (tempLang) {
				onInitLanguage(tempLang.code);
			} else {
				onInitLanguage(tempLangCode);
			}
		}

		// Hierarchy: account lang > user selected lang > ip lang > browser lang > default

		const currentDate = new Date();
		const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
		const localeUserCreatedDate = new Date(
			localStorage.getItem("userSelectedLanguageCodeCreatedAt") || 0
		);
		const localeIpCreatedDate = new Date(
			localStorage.getItem("ipCreatedAt") || 0
		);

		if (
			heartBeat &&
			Object.keys(memberData).length > 0 &&
			companyLanguages != undefined &&
			currentDate - localeUserCreatedDate > oneDayInMilliseconds
		) {
			const language = companyLanguages.find(
				(x) => x.id == memberData.languageSetting
			);
			localStorage.setItem("userSelectedLanguageCode", language.code);
			localStorage.setItem("userSelectedLanguageCodeCreatedAt", new Date());
			//console.log('Set lang by member')
			onInitLanguage(language.code);
		} else {
			if (
				localStorage.getItem("userSelectedLanguageCode") &&
				currentDate - localeUserCreatedDate <= oneDayInMilliseconds
			) {
				//console.log('Set lang by guest user')
				onInitLanguage(localStorage.getItem("userSelectedLanguageCode"));
			} else {
				if (
					!localStorage.getItem("ipLanguageCode") ||
					currentDate - localeIpCreatedDate > oneDayInMilliseconds
				) {
					// Disabled by Chris 20241009 - calling too many times and also causing CORS errors, remove for now
					// setLanguageByIPLocaleOrBrowserLocale();
				}
			}
		}
	}, [
		companyLanguages,
		memberData,
		heartBeat,
		languageSelected,
		localStorage.getItem(SessionKey._LANGUAGE),
	]);

	const setLanguageByIPLocaleOrBrowserLocale = async () => {
		const response = await ApiEngine.get("https://ipapi.co/json/");
		// const response = {
		// 	"ip": "146.70.29.196",
		// 	"network": "146.70.29.0/24",
		// 	"version": "IPv4",
		// 	"city": "Singapore",
		// 	"region": null,
		// 	"region_code": null,
		// 	"country": "SG",
		// 	"country_name": "Singapore",
		// 	"country_code": "SG",
		// 	"country_code_iso3": "SGP",
		// 	"country_capital": "Singapore",
		// 	"country_tld": ".sg",
		// 	"continent_code": "AS",
		// 	"in_eu": false,
		// 	"postal": "13",
		// 	"latitude": 1.2982,
		// 	"longitude": 103.7836,
		// 	"timezone": "Asia/Singapore",
		// 	"utc_offset": "+0800",
		// 	"country_calling_code": "+65",
		// 	"currency": "SGD",
		// 	"currency_name": "Dollar",
		// 	"languages": "cmn,en-SG,ms-SG,ta-SG,zh-SG",
		// 	"country_area": 692.7,
		// 	"country_population": 5638676,
		// 	"asn": "AS9009",
		// 	"org": "M247 Europe SRL"
		// }
		// const response = {};

		if (response && Object.keys(response).length > 0) {
			const languagesArray = response.languages.split(",");

			// Detect the first language tag that has a dash (e.g., en-SG, zh-Hans)
			const languageWithDash = languagesArray.find((lang) =>
				lang.includes("-")
			);

			const convertedMainLanguages = languageWithDash.toLowerCase();

			companyLanguages.forEach((language) => {
				if (convertedMainLanguages?.includes(language.code)) {
					// Set by ip language
					console.log("Set by ip language");
					localStorage.setItem("ipLanguageCode", convertedMainLanguages);
					localStorage.setItem("ipCreatedAt", new Date());
					onChangeLanguage(language);
				}
			});
		} else {
			// If IP geo location doesn't exist then use browser language
			const browserLanguage = navigator.language || navigator.userLanguage;
			const convertedBrowserLanguage = browserLanguage.toLowerCase();

			companyLanguages.forEach((language) => {
				if (convertedBrowserLanguage.includes(language.code)) {
					// Set by browser language
					console.log("Set by browser language");
					onChangeLanguage(language);
				}
			});
		}
	};

	async function checkIfReferralExist2(code) {
		let referralCode = code;
		if (code.includes(" +")) {
			referralCode = code.substring(0, code.indexOf(" +"));
		}
		let apiUrl =
			ApiUrl._API_CHECK_IF_REFERRAL_CODE_EXIST + "?code=" + referralCode;
		let responseJson = await ApiEngine.get(apiUrl);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			return true;
		} else {
			return false;
		}
	}
	async function checkIfReferralExist(code) {
		let referralCode = code;
		if (code.includes(" +")) {
			referralCode = code.substring(0, code.indexOf(" +"));
		}
		let apiUrl =
			ApiUrl._API_CHECK_IF_REFERRAL_CODE_EXIST + "?code=" + referralCode;
		let responseJson = await ApiEngine.get(apiUrl);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setDisabledRefrerral(true);
			setRegisterSteps(1);
		} else {
			_dispatch(
				showResponseMessage(
					responseJson[ApiKey._API_SUCCESS_KEY],
					t("ERROR_REFERRAL_CODE_CANNOT_BE_FOUND")
				)
			);
		}
	}

	async function checkUsername(username) {
		let avail = true;
		let responseJson = await ApiEngine.get(
			ApiUrl._API_CHECK_USERNAME +
				"?username=" +
				username +
				"&code=" +
				referralCode
		);
		avail = responseJson[ApiKey._API_SUCCESS_KEY];
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setIsUsernameAvailable(true);
		} else {
			setIsUsernameAvailable(false);
		}

		return avail;
	}

	function onchange(e) {
		const re = /^[0-9\b]+$/;
		let value = e.target.value;

		// Check if the value contains only digits
		if (re.test(value)) {
			// Limit the value to 12 characters
			if (value.length > 12) {
				value = value.slice(0, 12);
			}

			setIsDigit(true);
			setFormattedPhone(value);
		} else {
			return false;
			setIsDigit(false);
		}
	}

	const submitRegisterForm2 = async (data, e) => {
		setDisableRegister(true);
		setIsRegisterLoading(true);

		setPassword(data.password);
		let usernameAvail = await checkUsername(data.username);
		let phoneError = false;
		if (!usernameAvail) {
			setError("username", { message: t("USERNAME_TAKEN") });
		}

		let referralString = "";
		let referralCheck = true;
		if (referralCode !== "") {
			if (referralCode.length >= 8) {
				setShowCodeError(false);
				referralString = referralCode;
				setReferralCodeString(referralString);
				referralCheck = await checkIfReferralExist2(referralString);
				if (!referralCheck) {
					_dispatch(
						showResponseMessage(false, t("ERROR_REFERRAL_CODE_CANNOT_BE_FOUND"))
					);
					setIsRegisterLoading(false);
					setDisableRegister(false);
					return false;
				}
			} else {
				setShowCodeError(true);
			}
		} else {
			var defaultReferralCode = await ApiEngine.get(
				ApiUrl._API_GET_MASTER_REFERRAL_CODE
			);
			if (defaultReferralCode[ApiKey._API_SUCCESS_KEY]) {
				referralString = defaultReferralCode[ApiKey._API_DATA_KEY].code;

				setReferralCodeString(referralString);
			} else {
				_dispatch(
					showResponseMessage(false, t("ERROR_REFERRAL_CODE_CANNOT_BE_FOUND"))
				);
				setIsRegisterLoading(false);
				setDisableRegister(false);
				return false;
			}
		}

		if (
			typeof formattedPhone !== "undefined" &&
			(selectedPhonePrefix + formattedPhone).length >= 10
		) {
			if (!errors.phoneNumber && usernameAvail) {
				let params = {
					phoneNumber: selectedPhonePrefix + formattedPhone,
					referralCode: referralString,
					languageCode: "en",
					countdown: 0,
					TACExist: false,
					type: 1,
					usage: "createMember",
				};

				let responseJson = await ApiEngine.post(
					ApiUrl._API_GENERATE_OTP,
					createFormBody(params)
				);
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					const phoneNo = selectedPhonePrefix + formattedPhone;
					const phoneNum =
						Array.from({ length: phoneNo.length - 3 - 1 }, () => "*").join("") +
						phoneNo.slice(-4);
					setPhoneNumber(phoneNum);
					_setUsername(data.username);
					setPhoneNumberError(false);
					phoneError = false;
				} else {
					_dispatch(
						showMessage({
							custom: true,
							customIcon: (
								<img
									className="m-auto"
									src={alertIcon}
									height="120px"
									width="120px"
									alt="Custom Icon"
								/>
							),
							content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
						})
					);
					phoneError = true;
					setPhoneNumberError(true);
				}
			}
		} else {
			setError("registerPhone", { message: t("INVALID_PHONE_NUMBER") });
		}

		if (usernameAvail && !phoneError && referralCheck) {
			setTACCountdownPeriod(_TAC_VALID_TIMER / _SECONDS_IN_MS);
			setCountdownPeriod(_TAC_VALID_TIMER / _SECONDS_IN_MS);
			setRegisterSteps(2);
			setRegisterStepsThreeFirst(true);
		}

		setIsRegisterLoading(false);
		setDisableRegister(false);
	};

	async function submitPin(pin) {
		let params = {
			username: _username,
			email: _email,
			password: password,
			phoneNumber: selectedPhonePrefix + formattedPhone,
			referralCode: referralCodeString,
			languageSetting: "en",
			TAC: pin,
		};
		let responseJson = await ApiEngine.post(
			ApiUrl._API_REGISTER,
			createFormBody(params)
		);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setRegisterSteps(3);
			_dispatch(
				showMessage({
					type: AlertTypes._SUCCESS,
					content: t("REGISTRATION_COMPLETED"), // KT: Can say something about deposit now and get promotion etc maybe ?
					onConfirm: async () => {
						if (responseJson[ApiKey._API_SUCCESS_KEY]) {
							if (responseJson[ApiKey._API_DATA_KEY]) {
								// KT: Register completed.
								_dispatch(setRegisterModal(false));
								_dispatch(setDepositModal(true));
							} else {
								window.location.replace("https://www.google.com");
							}
						} else {
						}
					},
				})
			);
		} else {
			_dispatch(
				showResponseMessage(false, t(responseJson[ApiKey._API_MESSAGE_KEY]))
			);
			elePin["current"].clear();
			setPinNumber("");
			setEnteredPinNumber("");
			setEnteredPinNumberConfirm("");
			setPinNumberConfirmFocused(0);
		}
	}

	async function onKeyPress(key, index) {
		//setAllowPinClick(false);
		// 1st: have to check currentPinRef
		// 2nd: have to know which index is currently focused for altering the state.value
		// 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
		// 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin
		// let currentIndex = 0;
		let pinStr = tempPinNumber;
		let keyLength = key.split("");
		if (key !== "{bksp}" && key !== "&nbsp;") {
			pinStr = key;
			setTempPinNumber(pinStr);
			if (keyLength.length !== 6) {
			} else {
				setTempPinNumber("");
				setPinInputDisabled(true);
				setPinLoading(true);

				await submitPin(pinStr);

				setPinLoading(false);
				setPinInputDisabled(false);
			}
		} else {
			elePin["current"].clear();
			setPinNumber("");
			setTempPinNumber("");
			setPinNumberConfirmFocused(0);
			setTempPinNumberFocused(0);
		}
	}

	async function checkEmail(email) {
		let avail = true;
		let responseJson = await ApiEngine.get(
			ApiUrl._API_CHECK_EMAIL + "?email=" + email + "&code=" + referralCode
		);
		avail = responseJson[ApiKey._API_SUCCESS_KEY];
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setIsEmailAvailable(true);
		} else {
			setIsEmailAvailable(false);
		}

		return avail;
	}

	const submitForm = async (data, e) => {
		setDisableViaEmail(true);
		setViaEmailLoading(true);
		if (selectedPhonePrefix == "") {
			setSelectedPhonePrefix(PhoneNumberPrefix[0].value);
		}
		if (canSendTAC) {
			if (TACtimedOut || countdownPeriod > 0) {
				let emailAvail = await checkEmail(data.email);
				if (emailAvail) {
					let params = {
						phoneNumber: selectedPhonePrefix + formattedPhone,
						referralCode: referralCode,
						email: data.email,
						languageCode: "en",
						countdown: countdownPeriod,
						TACExist: !TACtimedOut,
						type: OTP_VIA_EMAIL,
						usage: "createMember",
					};

					let responseJson = await ApiEngine.post(
						ApiUrl._API_GENERATE_OTP,
						createFormBody(params)
					);
					if (responseJson[ApiKey._API_SUCCESS_KEY]) {
						_setEmail(data.email);
						_setEmailText("*".repeat(4) + data.email.substr(4));
						setTACsent(true);
						setTACtimedOut(false);
						setCountdownPeriod(0);
						setCountdownPeriod(_TAC_VALID_TIMER / _SECONDS_IN_MS);
						setTACBtnAble(false);
						setAttemp(attemp + 1);
						setTimeout(() => {
							setTACBtnAble(true);
							setTACtimedOut(true);
						}, _TAC_VALID_TIMER);
						setRegisterStepsViaEmail(false);
						setRegisterSteps(2);
						setRegisterStepsThreeFirst(true);
					}
				} else {
					setError("email", { message: t("EMAIL_TAKEN") });
					setDisableViaEmail(false);
					setViaEmailLoading(false);
				}
			}
		}

		setCanSendTAC(false);
		clearTimeout(TACtimer);
		clearTimeout(timerPeriod);
		setTACCountdownPeriod(_TAC_VALID_TIMER / _SECONDS_IN_MS);
		setTimeout(() => {
			setCanSendTAC(true);
		}, _SECONDS_IN_MS * 60 * _TAC_TIMEOUT_IN_MINUTES);

		setDisableViaEmail(false);
		setViaEmailLoading(false);
	};

	async function submitVerifyTAC(data) {
		setIsTACLoading(true);
		if (verifyState.button == 1) {
			let formData = {
				taccode: data.taccode,
				username: username,
			};
			let formBody = createMultiPartFormBody(formData);
			let TACjson = await ApiEngine.post(
				ApiUrl._API_VERIFY_RESET_PASSWORD_SMS,
				formBody
			);
			if (TACjson[ApiKey._API_SUCCESS_KEY]) {
				setVerifyPhoneTAC(false);
				setIsUpdatePassword(true);
			} else {
				_dispatch(
					showMessage({
						custom: true,
						customIcon: (
							<img
								className="m-auto"
								src={alertIcon}
								height="120px"
								width="120px"
								alt="Custom Icon"
							/>
						),
						content: t(TACjson[ApiKey._API_MESSAGE_KEY]),
					})
				);
			}
		} else if (verifyState.button == 2) {
			let formData = {
				language: "en",
				username: username,
				type: verifyState.button,
				usage: "resetPassword",
			};
			let formBody = createMultiPartFormBody(formData);
			//data["phoneNumber"] = formattedPhoneNumber;
			data["language"] = "en";
			var responseJson = await ApiEngine.post(
				ApiUrl._API_GENERATE_OTP,
				formBody
			);

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				setCountdownTAC(false);
				setPhoneNumber(responseJson.data);
				setTempPhoneNum(
					responseJson.data.replace(
						responseJson.data.substr(3, responseJson.data.length - 7),
						responseJson.data
							.substr(1, responseJson.data.length - 4)
							.replace(/./g, "*")
					)
				);
				setVerifyPhoneTAC(true);
				setIsForgetPassword(false);
				setAttemp(attemp + 1);
				verifyState.button = verifyState.button + 1;
				setCanSendTAC(false);
				clearTimeout(TACtimer);
				clearTimeout(timerPeriod);
				setTACCountdownPeriod(_TAC_VALID_TIMER / _SECONDS_IN_MS);
				setTACsent(true);
				setTACtimedOut(false);
				setCountdownPeriod(0);
				setCountdownPeriod(_TAC_VALID_TIMER / _SECONDS_IN_MS);
				clearTimeout(TACtimer);
				clearTimeout(timerPeriod);

				setTimeout(() => {
					setCanSendTAC(true);
					setCountdownTAC(true);
				}, _SECONDS_IN_MS * 60 * _TAC_TIMEOUT_IN_MINUTES);

				//resetForm();
			} else {
				_dispatch(
					showMessage({
						custom: true,
						customIcon: (
							<img
								className="m-auto"
								src={alertIcon}
								height="120px"
								width="120px"
								alt="Custom Icon"
							/>
						),
						content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
					})
				);
			}
		}
		setIsTACLoading(false);
	}

	async function submitNewPassword(data) {
		_dispatch(setBusy());
		let formData = {
			newpassword: data.newPassword,
			confpassword: data.confPassword,
			username: username,
		};

		let formBody = createMultiPartFormBody(formData);

		var responseJson = await ApiEngine.post(
			ApiUrl._API_MEMBER_RESET_PASSWORD_BEFORE_LOGIN,
			formBody
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setIsUpdatePassword(false);
			setIsPasswordUpdated(true);
		} else {
			_dispatch(
				showResponseMessage(
					responseJson[ApiKey._API_SUCCESS_KEY],
					t(responseJson[ApiKey._API_MESSAGE_KEY])
				)
			);
		}
		_dispatch(setIdle());
	}
	async function submitPasswordUpdated(data) {
		setIsUpdatePassword(false);
		setIsPasswordUpdated(false);
		setIsLogin(true);
		setIsModalVisible(false);
	}

	const goToLogin = async (data, e) => {
		_dispatch(setRegisterModal(false));
		_dispatch(setLoginModal(true));
	};

	const validateReferralCode = (e) => {
		var regexp = /^[A-Za-z0-9]+$/i;
		if (e.nativeEvent.data === null || regexp.test(e.nativeEvent.data)) {
			setReferralCode(e.target.value);
		}
	};

	const onChangeUsername = useCallback(
		debounce(async (e) => {
			var regexp = /^[\S]*^[^ \[\]`~\/!@#$%^&*()"':;?|{}=+_<>,.\\-]*$/g;
			if (e.nativeEvent.data === null || regexp.test(e.nativeEvent.data)) {
				_setUsername(e.target.value);
			}
		}, 500),
		[]
	);

	const handleChange = (event) => {
		// Remove spaces and convert to lowercase
		const newValue = event.target.value.replace(/\s/g, "").toLowerCase();
		_setEmail(newValue);
	};

	useEffect(() => {
		if (selectedChannel) {
			if (!stringIsNullOrEmpty(selectedChannel.attachment)) {
				setToggleModal(true);
			}
		}
	}, [selectedChannel]);

	useEffect(() => {
		const parsedReferralCode = params.get("code");
		if (parsedReferralCode != null && !heartBeat) {
			// getUpline(parsedReferralCode);
			_dispatch(setRegisterModal(true));
			setReferralCode(parsedReferralCode);
			// setRegisterSteps(2);
			checkIfReferralExist(parsedReferralCode);
		} else if (parsedReferralCode != null && heartBeat) {
			_dispatch(
				showMessage({
					//KT: The company icons details not loaded when reach this point.
					content: t("ALREADY_LOGIN"),
				})
			);
		}
	}, []);

	function handleClick(channel) {
		if (window.ReactNativeWebView) {
			window.ReactNativeWebView.postMessage(
				JSON.stringify({
					action: "chat",
					//url: `${channel.actionUrl}`,
					url:
						channel.actionUrl.match(/livechat/i) != null
							? `${window.location.origin}${channel.cta}`
							: `${channel.cta}`,
				})
			);
		} else {
			if (!stringIsNullOrEmpty(channel.cta)) {
				setToggleModal(false);
				window.open(channel.cta, "_blank");
				// window.location.href = channel.cta;
			} else {
				setSelectedChannel(channel);
				setToggleModal(true);
			}
		}
	}

	const toggleDropDown = () => setDropdownOpen((prevState) => !prevState);
	const onInitLanguage = (value) => {
		// console.log("onInitLanguage", value);
		let findLanguage = find(companyLanguages, { code: value });
		onChangeLanguage(findLanguage);
	};
	const onClickLanguage = async (value) => {
		let findLanguage = find(companyLanguages, { code: value });
		onChangeLanguage(findLanguage);

		localStorage.setItem("userSelectedLanguageCode", findLanguage.code);
		localStorage.setItem("userSelectedLanguageCodeCreatedAt", new Date());

		if (heartBeat) {
			let data = {};
			data["languageSetting"] = findLanguage.code;

			await ApiEngine.post(
				ApiUrl._API_UPDATE_MEMBER_LANGUAGE,
				createMultiPartFormBody(data)
			);

			// KT: Why we call this? After change language we need to bindMember for language?
			// bindMember(_dispatch);
		}
	};

	const onChangeLanguage = async (selectedLanguage) => {
		setLanguageSelected(selectedLanguage);

		const previousLocale = localStorage.getItem(SessionKey._LANGUAGE);
		if (selectedLanguage) {
			i18n.changeLanguage(selectedLanguage.code);
			_dispatch(saveLanguageId(selectedLanguage.code, true));

			// CHRIS_LOCALE: Update the URL to reflect the new language
			if (previousLocale !== selectedLanguage.code) {
				let locationPathname = location.pathname;

				// Use the regex to replace the locale at the start of the pathname
				if (locationPathname.startsWith(`/${previousLocale}`)) {
					const regex = new RegExp(`^/${previousLocale}(/|$)`);
					locationPathname = location.pathname.replace(
						regex,
						`/${selectedLanguage.code}/`
					);
				}
				// Ensure that the URL does not end with a slash, unless it's just the root "/"
				locationPathname =
					locationPathname === `/${selectedLanguage.code}/`
						? `/${selectedLanguage.code}`
						: locationPathname;

				locationPathname += location.search;
				
				_history.replace(locationPathname);

				// CHRIS_LOCALE: Update the current locale in WebUrl
				console.log("WebUrl.setCurrentLocale", selectedLanguage.code);
				WebUrl.setCurrentLocale(selectedLanguage.code);

				// Create a custom event
				const customEvent = new CustomEvent("changedLocale", {
					detail: { message: "Changed locale!" }
				});

				// Dispatch the event
				window.dispatchEvent(customEvent);
			}
		}
	};

	async function getRestore() {
		setBalanceRefresh(true);
		let responseJson = await ApiEngine.get(ApiUrl._API_RESTORE_ALL_WALLET);

		// KT: I dont see a point popping out "Success"
		/*_dispatch(
			showResponseMessage(
				responseJson[ApiKey._API_SUCCESS_KEY],
				responseJson[ApiKey._API_MESSAGE_KEY]
			)
		);*/
		bindMember(_dispatch);
		setBalanceRefresh(false);
	}

	async function getCompanyIcon() {
		_dispatch(setBusy());
		setChatLoading(true);

		let sideNavList = filter(companyIcons, { iconCategory: "side_nav" });
		if (sideNavList) {
			// Remove Membership icon if membership is not enabled
			if (!membershipEnabled) {
				// Filter out the "/membership" path from the list
				sideNavList = filter(
					sideNavList,
					(item) => item.pathname !== "/membership"
				);
			}

			setSideMenuList(sideNavList);
		}

		const logo = find(companyIcons, { iconCategory: "logo" });
		if (logo) {
			setLogo(logo.iconImage);
		}

		const smallLogo = find(companyIcons, { iconCategory: "small_logo" });
		if (smallLogo) {
			setSmallLogo(smallLogo.iconImage);
		}

		const alert = find(companyIcons, { iconCategory: "alert" });
		if (alert) {
			setAlertIcon(alert.iconImage);
		}

		const profile = find(companyIcons, { iconCategory: "profile" });
		if (profile) {
			setProfileIcon(profile.iconImage);
		}

		const loginBanner = find(companyIcons, { iconCategory: "login_popup" });
		if (loginBanner) {
			setLoginBanner(loginBanner.iconImage);
		}
		setChatLoading(false);
		_dispatch(setIdle());
	}

	const getTempID = () => {
		var tempId = "";

		if (!heartBeat) {
			if (localStorage.getItem(SessionKey._TEMP_GUID) !== "") {
				tempId = localStorage.getItem(SessionKey._TEMP_GUID);
			}
			if (tempId == "undefined" || tempId == null) tempId = "";
		}

		return tempId;
	};

	async function handleRegisterClick(channel) {
		if (window.ReactNativeWebView) {
			window.ReactNativeWebView.postMessage(
				JSON.stringify({
					action: "chat",
					//url: `${channel.actionUrl}`,
					url:
						channel.actionUrl.match(/livechat/i) != null
							? `${window.location.origin}${channel.url}`
							: `${channel.url}`,
				})
			);
		} else {
			var selectedReferralCode =
				referralCode == "" ? referralCodeString : referralCode;
			if (!stringIsNullOrEmpty(channel.url)) {
				setToggleModal(false);
				let referralString = "";

				if (stringIsNullOrEmpty(selectedReferralCode)) {
					var defaultReferralCode = await ApiEngine.get(
						ApiUrl._API_GET_MASTER_REFERRAL_CODE
					);
					if (defaultReferralCode[ApiKey._API_SUCCESS_KEY]) {
						referralString = defaultReferralCode[ApiKey._API_DATA_KEY].code;

						selectedReferralCode = referralString;
					} else {
						_dispatch(
							showResponseMessage(
								false,
								t("ERROR_REFERRAL_CODE_CANNOT_BE_FOUND")
							)
						);
					}
				}

				window.location.href = channel.url + selectedReferralCode;
			} else {
				setSelectedChannel(channel);
				setToggleModal(true);
			}
		}
	}
	const handleImageError = (e) => {
		// setIsImageBroken(true);
		e.target.style.display = "none";
	};

	function isNumber(value) {
		return typeof value === "number" && Number.isFinite(value);
	}

	return (
		<>
			{/* /////////t3 header */}
			<div className="t3-header">
				<div className={openMenu ? "first active" : "first"}>
					<div
						className="burger cursor-pointer"
						onClick={() => _dispatch(setOpenMenu(!openMenu))}
					>
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							className="theme-menu-icon"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M4.5 22.5C4.5 21.6715 5.17156 21 6 21H24C24.8284 21 25.5 21.6715 25.5 22.5C25.5 23.3284 24.8284 24 24 24H6C5.17156 24 4.5 23.3284 4.5 22.5ZM4.5 7.5C4.5 6.67156 5.17156 6 6 6H24C24.8284 6 25.5 6.67156 25.5 7.5C25.5 8.32842 24.8284 9 24 9H6C5.17156 9 4.5 8.32842 4.5 7.5ZM4.5 15C4.5 14.1716 5.17156 13.5 6 13.5H15C15.8285 13.5 16.5 14.1716 16.5 15C16.5 15.8285 15.8285 16.5 15 16.5H6C5.17156 16.5 4.5 15.8285 4.5 15Z"
							/>
						</svg>
					</div>
					<div
						className={openMenu && logo ? "t3-logo active" : "t3-logo"}
						onClick={() => _history.push(WebUrl._URL_MAIN)}
					>
						{logo ? (
							<>
								<img
									src={logo}
									className="img-responsive img1"
									alt="logo"
									onError={handleImageError}
									style={{ height: "100%" }}
								/>
								<img
									src={smallLogo}
									className="img-responsive img2"
									onError={handleImageError}
									alt="logo"
								/>
							</>
						) : (
							<></>
						)}
					</div>
				</div>

				<div className="second">
					{heartBeat == false ? (
						<>
							<div
								className="t3-header-login t3-primary-text-color button button-default hide-767"
								// onClick={() => setShowModalLogin(true)}
								onClick={() => {
									_dispatch(setLoginModal(true));
								}}
							>
								{t("LOGIN")}
							</div>
							<div
								className="t3-header-register t3-primary-bg-color hide-767"
								// onClick={() => setShowModalRegister(true)}
								onClick={() => _dispatch(setRegisterModal(true))}
							>
								{t("REGISTER")}
							</div>
						</>
					) : (
						// </div>
						// <div className="second">
						<>
							<div className="t3-header-balance-deposit-box">
								<div
									style={{
										cursor: "pointer",
										display: "flex",
										alignItems: "center",
										position: "relative",
									}}
									onClick={() => {
										_history.push(WebUrl._URL_SETTINGS);
									}}
								>
									{/* {
									isBalanceLoading ? 	
										(<Spinner
										className="dynamic-table-spinner"
										animation="border"
										role="status"
										size="sm"
										style={{
											verticalAlign: "middle",
											width: "0.875rem",
											height: "0.875rem",
											marginRight: 3,
											position:"absolute",
											left:"-14px"
										}}
										></Spinner>) : ""
										} */}
									<div className="header-balance-column">
										<span className="font-size-header-balance">
											{t("BALANCE")}
										</span>
										<b className="font-size-header-balance">
											{!isNumber(walletBalance)
												? walletBalance
												: numberWithCurrencyFormat(walletBalance, 2, true)}
										</b>
									</div>
								</div>
								<div className="t3-refresh-btn" onClick={() => getRestore()}>
									{/* refresh */}
									{balanceRefresh ? (
										<Spinner
											className="dynamic-table-spinner"
											animation="border"
											role="status"
											size="sm"
											style={{
												verticalAlign: "middle",
												width: "0.875rem",
												height: "0.875rem",
												marginLeft: 3,
											}}
										></Spinner>
									) : (
										<VIcon icon={themeIcons.refresh} className="vicon" />
									)}
								</div>
								<div
									className="t3-header-deposit"
									// onClick={() => setShowDepositAndWithdrawPopup(true)}
									onClick={() => {
										_dispatch(setDepositModal(true));
									}}
								>
									<div>
										<VIcon icon={themeIcons.deposit} className="vicon" />
									</div>
									<div className="text">{t("DEPOSIT")}</div>
								</div>
							</div>
							<div
								className="t3-header-profile-box"
								onClick={() => {
									_history.push(WebUrl._URL_SETTINGS);
								}}
							>
								<div className="second">
									<div>{_userData["username"]}</div>
									{membershipEnabled &&
										<div>
											<span>{t(upperCase(_userData["vipName"]))}</span>
										</div>
									}
								</div>
								<div className="third">
									<svg
										focusable="false"
										color="#ffffff"
										aria-hidden="true"
										viewBox="0 0 24 24"
										data-testid="ExpandMoreIcon"
										fill="white"
										height="20px"
									>
										<path d="M8.59 16.59 13.17 12 8.59 7.41 10 6l6 6-6 6z"></path>
									</svg>
								</div>
							</div>
						</>
						// </div>
					)}

					{companyLanguages && companyLanguages.length > 1 && (
						<UncontrolledDropdown
							className="drop-down-language"
							isOpen={dropdownOpen}
							toggle={toggleDropDown}
							direction="down"
						>
							<DropdownToggle>
								{" "}
								{languageSelected && languageSelected.icon ? (
									<img
										src={languageSelected?.icon}
										className="img-responsive language-flag"
										alt="profile"
									/>
								) : (
									<img
										src={require("../../assets/img/template_1/default_lang.png")}
										className="img-responsive language-flag"
										alt="profile"
									/>
								)}
								<svg
									focusable="false"
									color="#ffffff"
									aria-hidden="true"
									viewBox="0 0 24 24"
									data-testid="ExpandMoreIcon"
									fill="white"
									height="20px"
								>
									<path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
								</svg>
							</DropdownToggle>
							<DropdownMenu end={"true"}>
								{companyLanguages &&
									companyLanguages.length > 0 &&
									map(companyLanguages, (item, index) => {
										return (
											<DropdownItem
												key={index}
												onClick={() => onClickLanguage(item.code)}
											>
												{item.icon ? (
													<img src={item.icon} alt="flag" height="20px" />
												) : (
													<img
														src={require("../../assets/img/template_1/default_lang.png")}
														className="img-responsive"
														alt="profile"
														width="20px"
													/>
												)}{" "}
												<span className="ml-1">{item.name}</span>
											</DropdownItem>
										);
									})}
							</DropdownMenu>
						</UncontrolledDropdown>
					)}

					<div
						className="burger cursor-pointer show-767"
						onClick={() => setOpenMobileSideMenu(!openMobileSideMenu)}
					>
						<VIcon
							icon={themeIcons.menu1}
							className="theme-icon-size-20 theme-menu-icon"
						/>
					</div>
				</div>
			</div>

			{/* /////////t3 side menu */}
			<div className="t3-side-menu">
				<div className={openMenu ? "left active" : "left"}>
					<div className="t3-sidemenu-box">
						{sideMenuList &&
							sideMenuList.length &&
							map(sideMenuList, (item, index) => {
								return (
									<div
										key={index}
										className={
											"t3-sidemenu-item " +
											"t3-s-" +
											item["id"] +
											(tabActive == item["iconName"] ? " active" : "") +
											" sidemenu-nav-" + item["iconName"].toLowerCase().replace(" ", "-")
										}
										onClick={() => {
											// CHRIS_LOCALE: Ensure that locale is prepended
											var locale = localStorage.getItem(SessionKey._LANGUAGE);
											let itemPathname = stringIsNullOrEmpty(item["pathname"])
												? ""
												: item["pathname"];
											itemPathname = locale
												? "/" + locale + itemPathname
												: itemPathname;

											if (heartBeat) {
												_history.push({
													pathname: itemPathname,
													state: {
														reference: item["iconName"],
													},
												});
											} else {
												if (item["pathname"] == "/recent-games") {
													_dispatch(setLoginModal(true));
												} else {
													_history.push({
														pathname: stringIsNullOrEmpty(item["pathname"])
															? ""
															: item["pathname"],
														state: {
															reference: item["iconName"],
														},
													});
												}
											}
										}}
									>
										<div className="first">
											<img
												src={item["iconImage"]}
												className="img-responsive"
												alt={item["iconName"]}
											/>
										</div>
										<h6>{t(item["iconName"])}</h6>
										{item["pathname"] === "/promotion" &&
											totalPromoClaimable > 0 && (
												<div className="sidenav-unread-count badge main-pulse">
													{totalPromoClaimable}
												</div>
											)}
									</div>
								);
							})}
					</div>
					{!chatLoading && (
						<div
							className={"t3-side-menu-container " + (openMenu ? "active" : "")}
						>
							<div className="text-center t3-chat-label">
								{t("24/7_LIVE_SUPPORT")}
							</div>
							<div className="t3-chatbox-active">
								<div
									onClick={() => {
										_dispatch(setOpenMenu(true));
										_history.push(WebUrl._URL_LIVECHAT);
									}}
									style={{ position: "relative" }}
								>
									{/* Large Screen SVG with Combined Icon */}
									<svg
										className="bg-svg-chat"
										width="175"
										height="35"
										viewBox="0 0 175 35"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										aria-label="Chat Background"
									>
										<rect
											width="175"
											height="35"
											rx="17.5"
											fill="url(#chat_bg_gradient)"
										/>
										<text
											x="60%"
											y="50%"
											alignmentBaseline="middle"
											textAnchor="middle"
											fill="white"
											fontSize="16"
											fontFamily="Poppins"
											fontWeight="400"
										>
											{t("CHAT_NOW")}
										</text>
										<defs>
											<linearGradient
												id="chat_bg_gradient"
												x1="0"
												y1="17.5"
												x2="175"
												y2="17.5"
												gradientUnits="userSpaceOnUse"
											>
												<stop className="gradient-stop-1" stopColor="#FF4A4A" />
												<stop
													className="gradient-stop-2"
													offset="1"
													stopColor="#C91212"
												/>
											</linearGradient>
										</defs>
									</svg>

									<svg
										className="white-svg-chat-icon"
										width="20"
									>
										<g clipPath="url(#clip0_10139_15679)">
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M0.000556458 7.50007C0.000556458 3.70893 3.64546 0.625259 8.12533 0.625259C12.6052 0.625259 16.2501 3.70893 16.2501 7.50007C16.2501 11.2912 12.6052 14.3749 8.12533 14.3749H0.625539C0.373046 14.3749 0.144303 14.2224 0.0480552 13.9899C-0.0494422 13.7562 0.00430634 13.4874 0.183051 13.3087L1.73801 11.7537C0.61304 10.5437 0.000556458 9.05503 0.000556458 7.50007ZM4.37544 9.37502H9.3753C9.72029 9.37502 10.0003 9.09503 10.0003 8.75004C10.0003 8.40505 9.72029 8.12505 9.3753 8.12505H4.37544C4.03045 8.12505 3.75045 8.40505 3.75045 8.75004C3.75045 9.09503 4.03045 9.37502 4.37544 9.37502ZM4.37544 6.87509H11.8752C12.2202 6.87509 12.5002 6.5951 12.5002 6.25011C12.5002 5.90512 12.2202 5.62512 11.8752 5.62512H4.37544C4.03045 5.62512 3.75045 5.90512 3.75045 6.25011C3.75045 6.5951 4.03045 6.87509 4.37544 6.87509ZM20 12.4999C20 14.0549 19.3875 15.5449 18.2626 16.7536L19.8175 18.3073C19.995 18.486 20.0488 18.7548 19.9525 18.9885C19.8563 19.2223 19.6275 19.3747 19.375 19.3747H11.8753C8.72659 19.3747 5.99541 17.8473 4.64795 15.6248H8.12535C13.2765 15.6248 17.4664 12.0062 17.4976 7.54882C19.0363 8.80004 20 10.555 20 12.4999Z"
												fill="white"
											/>
										</g>
										<defs>
											<clipPath id="clip0_10139_15679">
												<rect width="20" height="20" fill="white" />
											</clipPath>
										</defs>
									</svg>

									{/* Small Screen SVG with Combined Icon */}
									<svg
										className="sm-svg-chat"
										width="75"
										height="30"
										viewBox="0 0 75 30"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										aria-label="Chat Button"
									>
										<rect
											width="75"
											height="30"
											rx="15"
											fill="url(#chat_button_gradient)"
										/>
										<text
											x="60%"
											y="50%"
											alignmentBaseline="middle"
											textAnchor="middle"
											fill="white"
											fontSize="12"
											fontFamily="Poppins"
											fontWeight="400"
										>
											{t("CHAT")}
										</text>
										<defs>
											<linearGradient
												id="chat_button_gradient"
												x1="0"
												y1="15"
												x2="75"
												y2="15"
												gradientUnits="userSpaceOnUse"
											>
												<stop className="gradient-stop-1" stopColor="#FF4A4A" />
												<stop
													className="gradient-stop-2"
													offset="1"
													stopColor="#C91212"
												/>
											</linearGradient>
										</defs>
									</svg>
									{unreadLivechat > 0 && (
										<div className="sidenav-unread-count badge main-pulse live-chat">
											{unreadLivechat}
										</div>
									)}
								</div>
							</div>
						</div>
					)}

					{channels && channels.length > 0 && (
						<div
							className={"t3-side-menu-container " + (openMenu ? "active" : "")}
						>
							<h5 className="text-center">{t("SOCIAL_MEDIA")}</h5>
							<div className="t3-sm-box-active">
								{map(channels, (channel, index) => {
									return (
										<div
											key={index}
											className="t3-sm-item"
											onClick={() => {
												if (!stringIsNullOrEmpty(channel.cta)) {
													handleClick(channel);
												} else if (channel.name == "Livechat") {
													setIsLiveChatOpen(true);
												}
											}}
											style={{ cursor: "pointer" }}
										>
											<div className="first">
												<img
													src={channel.image}
													className="img-responsive"
													alt={channel.description}
												/>
											</div>
										</div>
									);
								})}
							</div>

							<div className="t3-sm-box">
								{map(channels, (channel, index) => {
									return (
										<div
											key={index}
											className="t3-sm-item"
											onClick={() => {
												if (!stringIsNullOrEmpty(channel.actionUrl)) {
													handleClick(channel);
												} else if (channel.name == "Livechat") {
													setIsLiveChatOpen(true);
												}
											}}
											style={{ cursor: "pointer" }}
										>
											<div className="first">
												<img
													src={channel.image}
													className="img-responsive"
													alt={channel.description}
												/>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					)}
				</div>
			</div>

			{/* /////////t3 mobile menu pop.up */}
			{openMobileSideMenu && (
				<>
					<div className="t3-mobile-side-menu-container">
						<div className="t3-m-sm-header">
							<div className="t3-logo">
								<img src={logo} className="img-responsive" alt="logo" />
							</div>
							<div
								className="t3-close-modal"
								onClick={() => setOpenMobileSideMenu(!openMobileSideMenu)}
							>
								<VIcon icon={themeIcons.close} className="vicon" />
							</div>
						</div>
						<div className="t3-mobile-side-menu-content">
							<div className="t3-sidemenu-box">
								{sideMenuList &&
									sideMenuList.length &&
									map(sideMenuList, (item, index) => {
										return (
											<div
												key={index}
												className={"t3-sidemenu-item " + "t3-s-" + item["id"]}
												onClick={() => {
													// CHRIS_LOCALE: Ensure that locale is prepended
													var locale = localStorage.getItem(
														SessionKey._LANGUAGE
													);
													let itemPathname = stringIsNullOrEmpty(
														item["pathname"]
													)
														? ""
														: item["pathname"];
													itemPathname = locale
														? "/" + locale + itemPathname
														: itemPathname;

													if (heartBeat) {
														setOpenMobileSideMenu(!openMobileSideMenu);
														_history.push({
															pathname: itemPathname,
															state: {
																reference: item["iconName"],
															},
														});
														if (item["id"] == 8) {
															localStorage.setItem("defaultActiveNav", 2);
														} else if (item["id"] == 9) {
															localStorage.setItem("defaultActiveNav", 1);
														} else {
															localStorage.setItem("defaultActiveNav", 0);
														}
													} else {
														if (item["pathname"] == "/recent-games") {
															_dispatch(setLoginModal(true));
															setOpenMobileSideMenu(!openMobileSideMenu);
														} else {
															setOpenMobileSideMenu(!openMobileSideMenu);
															_history.push({
																pathname: itemPathname,
																state: {
																	reference: item["iconName"],
																},
															});

															if (item["id"] == 8) {
																localStorage.setItem("defaultActiveNav", 2);
															} else {
																localStorage.setItem("defaultActiveNav", 0);
															}
														}
													}
												}}
											>
												<div className="first">
													<img
														src={item["iconImage"]}
														className="img-responsive"
														alt={item["iconName"]}
													/>
												</div>
												<h6>{t(item["iconName"])}</h6>
											</div>
										);
									})}
							</div>
						</div>
						<div className="t3-msm-social-box">
							<h5 className="text-center">{t("SOCIAL_MEDIA")}</h5>
							<div className="t3-sm-box">
								{channels &&
									channels.length &&
									map(channels, (channel, index) => {
										return (
											<div
												key={index}
												className="t3-sm-item"
												onClick={() => {
													if (!stringIsNullOrEmpty(channel.cta)) {
														handleClick(channel);
													}
												}}
												style={{ cursor: "pointer" }}
											>
												<div className="first">
													<img
														src={channel.image}
														className="img-responsive"
														alt={channel.description}
													/>
												</div>
											</div>
										);
									})}
							</div>
						</div>
					</div>
				</>
			)}

			{/* ///register pop.up */}
			<Modal
				id="t3-login-register-modal"
				fade={false}
				contentClassName={"t3-login-register-modal"}
				// isOpen={registerModal}
				isOpen={false}
				toggle={() => {
					_dispatch(setRegisterModal(false));
					// setShowModalRegister(false);
				}}
				backdrop={"static"}
				centered
			>
				<ModalBody>
					<div className="t3-lr-modal-container">
						<div className="t3-lr-modal-header">
							<div className="t3-logo">
								<img src={logo} className="img-responsive" alt="logo" />
							</div>
							<div
								className="t3-close-modal"
								// onClick={() => setShowModalRegister(!showModalRegister)}
								onClick={() => {
									if (params.get("code") != referralCode) {
										setReferralCode("");
									}
									_dispatch(setRegisterModal(false));
								}}
							>
								<VIcon icon={themeIcons.close} className="vicon" />
							</div>
						</div>
						<div className="t3-lr-modal-body register">
							<div className="left">
								{/*<div className="text">*/}
								{/*	{t("BUILD_THE_BEST_CASINO_TOGETHER")}*/}
								{/*</div>*/}
								<div className="image">
									<img
										src={loginBanner}
										className="img-responsive"
										alt="BUILD THE BEST CASINO TOGETHER"
									/>
								</div>
							</div>
							<div className="right">
								{registerSteps == 1 && (
									<form
										onSubmit={handleSubmit(submitRegisterForm2)}
										autocomplete="off"
									>
										<div className="t3-lr-form">
											<div className="t3-input-container mt-3">
												<label>{t("ENTER_USERNAME")}</label>
												<div>
													<Input
														ref={register({
															required: true,
															// validate: async (value) =>
															// 	(await checkUsername(value)) ||
															// 	(console.log("test"), "USERNAME_TAKEN"),
															pattern: {
																value: /^(?=.*[A-Za-z0-9])[A-Za-z0-9]{4,9}$/i,
																message: t(
																	"USERNAME_REQUIRES_ALPHANUMERIC_WITH_LOWERCASE_MINIMUM_4_9_CHARACTERS_ONLY"
																),
															},
														})}
														id="loginUsername"
														name="username"
														// value={_username.toLowerCase()}
														// onInput={(e) => validateUsername(e)}
														// onChange={onChangeUsername}
														onInput={onChangeUsername}
														errors={errors}
														className="t3-custom-input-text"
														autoFocus
														autoComplete="off"
														onKeyDown={(e) => {
															const key = e.key;
															const allowedKeys = [
																"Backspace",
																"ArrowLeft",
																"ArrowRight",
																"Delete",
																"Tab",
															];

															// Allow lowercase letters, numbers, and allowed keys
															if (
																!/[a-z0-9]/.test(key) &&
																!allowedKeys.includes(key)
															) {
																if (/[A-Z]/.test(key)) {
																	e.preventDefault();
																	const start = e.target.selectionStart;
																	const end = e.target.selectionEnd;

																	// Insert the lowercase version of the uppercase key
																	e.target.value =
																		e.target.value.slice(0, start) +
																		key.toLowerCase() +
																		e.target.value.slice(end);
																	// Adjust the cursor position to be after the inserted character
																	e.target.setSelectionRange(
																		start + 1,
																		start + 1
																	);
																} else {
																	// Prevent any other unwanted key input
																	e.preventDefault();
																}
															}
														}}
													/>
													{/* {isObjectEmpty(errors) &&
														isUsernameAvailable &&
														_username != "" && (
															<div className="d-flex align-items-center user-available mb-4">
																<div className="font12 text-lgray3 second text-customgreen">
																	{t("THIS_USERNAME_IS_AVAILABLE")}
																</div>
															</div>
														)} */}
												</div>
											</div>
											<div className="t3-input-container mt-3">
												<label>{t("MOBILE_NUMBER")}</label>
											</div>
											<div className="t3-contact-number-box">
												<div className="first">
													<select
														onChange={(e) => {
															setSelectedPhonePrefix(e.target.value);
														}}
													>
														{PhoneNumberPrefix != undefined &&
															PhoneNumberPrefix.map((prefix, index) => {
																return (
																	<option
																		key={index}
																		className={"phone-prefix-" + (index + 1)}
																		value={prefix.value}
																	>
																		{prefix.label}
																	</option>
																);
															})}
													</select>
												</div>
												<div className="second">
													<input
														name="registerPhone"
														className="t3-custom-input-text"
														autoComplete="new-password"
														ref={register({
															required: true,
															minLength: {
																value: 8,
																message: t("MIN_CHARACTERS", { length: 8 }),
															},
															maxLength: {
																value: 11,
																message: t("MAX_CHARACTERS", { length: 11 }),
															},
															pattern: {
																value: /^[0-9]+$/g,
																message:
																	t("MIN_CHARACTERS", { length: 8 }) +
																	" " +
																	t("AND") +
																	" " +
																	t("ONLY_NUMBERS"),
															},
														})}
														errors={errors}
														onKeyDown={(e) => {
															const key = e.key;
															const allowedKeys = [
																"Backspace",
																"ArrowLeft",
																"ArrowRight",
																"Delete",
																"Tab",
															];

															if (
																!/[0-9]/.test(key) &&
																!allowedKeys.includes(key)
															) {
																e.preventDefault();
															}
														}}
														onChange={(e) => onchange(e)}
													/>

													{phoneNumberError == true ? (
														<div className="d-flex align-items-center user-available">
															<div className="font12 text-lgray3 second text-customred">
																{t("NUMBER_NOT_AVAILABLE")}
															</div>
														</div>
													) : errors.registerPhone ? (
														<div className="d-flex align-items-center user-available">
															<div className="font12 text-lgray3 second text-customred">
																{t(errors.registerPhone.message)}
															</div>
														</div>
													) : (
														""
													)}
												</div>
											</div>
											<div className="t3-input-container mt-3">
												<label>{t("ENTER_YOUR_PASSWORD")}</label>
												<div style={{ position: "relative" }}>
													<Input
														ref={register({
															required: true,
															minLength: {
																value: _MIN_PASSWORD_LENGTH,
																message: t("MIN_CHARACTERS", {
																	length: _MIN_PASSWORD_LENGTH,
																}),
															},
															pattern: {
																value:
																	/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/i,
																message: t("PASSWORD_MESSAGE"),
															},
														})}
														onKeyDown={(e) => {
															const key = e.key;
															const allowedKeys = [
																"Backspace",
																"ArrowLeft",
																"ArrowRight",
																"Delete",
																"Tab",
															];

															if (
																!/[a-zA-Z0-9]/.test(key) &&
																!allowedKeys.includes(key)
															) {
																e.preventDefault();
															}
														}}
														errors={errors}
														id="password"
														name="password"
														type={hideLoginPassword ? "password" : "text"}
														className="t3-custom-input-text"
														autocomplete="new-password"
														autoFocus
													/>
													<VIcon
														icon={
															hideLoginPassword
																? themeIcons.eyeBlocked
																: themeIcons.eye
														}
														className="vicon"
														style={{ top: "20px" }}
														onClick={() =>
															setHideLoginPassword(!hideLoginPassword)
														}
													/>
												</div>
											</div>

											<div className="pl-2 pr-2">
												<div className="font12 text-lgray6 second">
													<VIcon
														icon={themeIcons.checkmarkOutline}
														className="theme-icon-size-15 mr-1"
													/>
													{t("REGISTER_PAGE_MIN_9_CHARS")}
												</div>
												<div className="font12 text-lgray6 second">
													<VIcon
														icon={themeIcons.checkmarkOutline}
														className="theme-icon-size-15 mr-1"
													/>
													{t("ONLY_LETTERS_NUMBERS_9")}
												</div>
												<div className="font12 text-lgray6 second">
													<VIcon
														icon={themeIcons.checkmarkOutline}
														className="theme-icon-size-15 mr-1"
													/>
													{t("NO_SPECIAL_SYMBOLS")}
												</div>
											</div>
											<div className="t3-input-container mt-3">
												<label>
													{t("KEY_IN_YOUR_REFERRAL_CODE")} ({t("OPTIONAL")})
												</label>
												<div>
													<Input
														id="referral"
														name="referral"
														className={
															"t3-custom-input-text" +
															(disabledRefrerral ? " disabled" : "")
														}
														onInput={(e) => validateReferralCode(e)}
														value={referralCode}
														autoFocus
														// disabled={true}
													/>
												</div>
												{showCodeError && (
													<div className="d-flex align-items-center user-available mb-4">
														<div className="font12 text-lgray3 second text-customred">
															{t("INVALID_REFERRAL_CODE")}
														</div>
													</div>
												)}
											</div>
											<div className="t3-lr-button-box mt-5">
												<div>
													<button
														className="t3-custom-light-btn"
														type="submit"
														onClick={() => console.log("btnsubmit")}
														disabled={disableRegister}
													>
														{isRegisterLoading ? (
															<Spinner
																className="dynamic-table-spinner"
																animation="border"
																role="status"
																size="sm"
																style={{
																	verticalAlign: "middle",
																	width: "1.5rem",
																	height: "1.5rem",
																	marginLeft: 3,
																}}
															></Spinner>
														) : (
															t("REGISTER")
														)}
													</button>
												</div>
											</div>
											<div className="t3-lr-button-box mt-3">
												<span>{t("ALREADY_HAVE_AN_ACCOUNT")}</span>{" "}
												<b
													className="header-login-btn"
													onClick={() => {
														_dispatch(setRegisterModal(false));
														_dispatch(setLoginModal(true));
													}}
												>
													{t("LOGIN_NOW")}
												</b>
											</div>
										</div>
									</form>
								)}

								{registerSteps == 2 && (
									<>
										{registerStepsThreeFirst && (
											<div className="t3-lr-pin-box">
												<h3 className="t3-pin-title mb-0">
													{t("VERIFY_YOUR_NUMBER")}
												</h3>
												<div className="t3-pin-title2 mb-3">
													{t("ENTER_THE_CODE_WE_SENT_TO")}{" "}
													{_emailText != "" ? _emailText : phoneNumber}.
												</div>
												<PinInput
													length={6}
													// initialValue="------"
													ref={elePin}
													// secret
													type="numeric"
													disabled={pinInputDisabled}
													inputMode="numeric"
													inputStyle={{ border: "0", margin: "0px 5px" }}
													regexCriteria={/^[ 0-9_@./#&+-]*$/}
													onComplete={(value, index) => {
														onKeyPress(value, index);
													}}
													// onChange={(value, index) => {
													// 	onKeyPress(value,index);
													// 	// setPinNumberFocused(index);
													// }}
												/>
												{TACsent && countdownPeriod != 0 && (
													<p className="tac-countdown mt-2 font12">
														{t("TAC_SENT")} {countdownPeriod}s
													</p>
												)}

												{pinLoading ? (
													<div className="mt-2">
														<Spinner
															className="dynamic-table-spinner"
															animation="border"
															role="status"
															size="sm"
															style={{
																verticalAlign: "middle",
																width: "1.5rem",
																height: "1.5rem",
																marginLeft: 3,
															}}
														></Spinner>
													</div>
												) : (
													""
												)}

												{countdownPeriod == 0 && (
													<div>
														<button
															className="tac-countdown"
															style={{
																background: "none",
																border: "none",
																padding: 0,
																cursor: "pointer",
															}}
														>
															{t("DIDNT_RECEIVE_A_CODE_TRY_OTHER_METHOD")}
															<br />
															<span
																dangerouslySetInnerHTML={{
																	__html: t(
																		"TRY_CONTACTING_OUR_CUSTOMER_SERVICE",
																		{ url: customerServiceUrl }
																	),
																}}
															/>
														</button>

														{/* {attemp == 1 && (
															<div>
																<button
																	className="t3-custom-light-btn"
																	onClick={() => {
																		setRegisterStepsThreeFirst(false);
																		setRegisterStepsViaEmail(true);
																	}}
																	disabled={pinInputDisabled}
																>
																	{t("RECEIVE_OTP_VIA_EMAIL")}
																</button>
															</div>
														)}
														{attemp == 2 && (
															<div>
																<button
																	className="t3-custom-light-btn"
																	onClick={() => setIsLiveChatOpen(true)}
																	disabled={pinInputDisabled}
																>
																	{t("CONTACT_CUSTOMER_SERVICE")}
																</button>
															</div>
														)} */}
													</div>
												)}
											</div>
										)}

										{!registerStepsThreeFirst && registerStepsViaEmail && (
											<form onSubmit={handleSubmit(submitForm)}>
												<div className="t3-lr-form">
													<div className="t3-input-container mt-3">
														<label>{t("RECEIVE_OTP_VIA_EMAIL")}</label>
														<Input
															ref={register({
																required: true,

																pattern: {
																	value:
																		/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
																	message: t("INVALID_EMAIL"),
																},
															})}
															id="loginUsername"
															name="email"
															placeholder={t("EMAIL")}
															onChange={handleChange}
															errors={errors}
															className="t3-custom-input-text"
															autoFocus
														/>

														<div className="t3-lr-button-box mt-5">
															<div>
																<button
																	className="t3-custom-light-btn"
																	disabled={disableViaEmail}
																>
																	{viaEmailLoading ? (
																		<Spinner
																			className="dynamic-table-spinner"
																			animation="border"
																			role="status"
																			size="sm"
																			style={{
																				verticalAlign: "middle",
																				width: "1.5rem",
																				height: "1.5rem",
																				marginLeft: 3,
																			}}
																		></Spinner>
																	) : (
																		t("CONTINUE")
																	)}
																</button>
															</div>
														</div>
													</div>
												</div>
											</form>
										)}
									</>
								)}
								{registerSteps == 3 && (
									<>
										<div className="t3-lr-success">
											<h2 className="t3-primary-text-color">
												{t("REGISTRATION_COMPLETED")}
											</h2>
											{/* KT: Maybe can replace this with welcoming msgs etc. 
											<div className="t3-lr-button-box mt-3">
												<div>
													<button
														className="t3-custom-light-btn"
														onClick={() => {
															handleSubmit(goToLogin)();
														}}
													>
														{isLoginLoading ? (
															<Spinner
																className="dynamic-table-spinner"
																animation="border"
																role="status"
																size="sm"
																style={{
																	verticalAlign: "middle",
																	width: "1.5rem",
																	height: "1.5rem",
																	marginLeft: 3,
																}}
															></Spinner>
														) : (
															t("LOGIN")
														)}
													</button>
												</div>
											</div>*/}
										</div>
									</>
								)}
							</div>
						</div>
						{registerChannel && registerChannel.length > 0 && (
							<div
								className="t3-lr-modal-body-social-mdedia t3-side-menu-container active"
								style={{ textAlign: "center" }}
							>
								<div className="t3-register-channel-container">
									<div className="mt-3">
										{t("REGISTER_THROUGH_SOCIAL_MEDIA")}
									</div>
									<div></div>
									<div className="t3-sm-box-active mt-3">
										{map(registerChannel, (channel, index) => {
											return (
												<div
													key={index}
													className="ml-2 register-channel"
													onClick={() => {
														if (!stringIsNullOrEmpty(channel.url)) {
															handleRegisterClick(channel);
														}
													}}
													style={{ cursor: "pointer" }}
												>
													<div className="first">
														<img
															src={channel.image}
															className="img-responsive"
															alt={channel.usage}
														/>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						)}
					</div>
				</ModalBody>
			</Modal>

			{/* Seem Like Not Using Anymore */}
			<Modal
				id="t3-login-register-modal"
				fade={false}
				contentClassName={"t3-login-register-modal"}
				isOpen={isModalVisible}
				backdrop={"static"}
				toggle={() => {
					setIsModalVisible(false);
					setIsForgetPassword(true);
					setVerifyPhoneTAC(false);
					setIsUpdatePassword(false);
					setIsPasswordUpdated(false);
					setIsLogin(true);
				}}
				centered
			>
				<ModalBody>
					<div className="t3-lr-modal-container">
						<div className="t3-lr-modal-header">
							<div className="t3-logo">
								<img src={logo} className="img-responsive" alt="logo" />
							</div>
							<div
								className="t3-close-modal"
								onClick={() => setIsModalVisible(!isModalVisible)}
							>
								<VIcon icon={themeIcons.close} className="vicon" />
							</div>
						</div>
						<div className="t3-lr-modal-body">
							<div className="left">
								{/*<div className="text">*/}
								{/*	{t("BUILD_THE_BEST_CASINO_TOGETHER")}*/}
								{/*</div>*/}
								<div className="image">
									<img
										src={loginBanner}
										className="img-responsive"
										alt="BUILD THE BEST CASINO TOGETHER"
									/>
								</div>
							</div>
							<div className="right">
								{verifyPhoneTAC && (
									<form onSubmit={tacVerifyHandleSubmit(submitVerifyTAC)}>
										<label>{t("ENTER_VERIFICATION_CODE")}</label>
										<Input
											type="text"
											id="taccode"
											name="taccode"
											ref={tacVerifyRegister()}
											className="t3-custom-input-text"
											placeholder={t("TAC_CODE")}
											autoComplete="off"
										/>
										<span className="font12 text-lgray3 text-capitalize">
											{t("PHONE_TAC_SEND_TO")}
											{tempPhoneNum}
										</span>
										{TACsent && countdownPeriod != 0 && (
											<p className="tac-countdown mt-2 font12 text-capitalize">
												{t("TAC_SENT")} {countdownPeriod}s
											</p>
										)}
										<div>
											<button
												className="t3-custom-light-btn"
												type="submit"
												onClick={() => (verifyState.button = "1")}
											>
												{t("CONTINUE")}
												{isTACLoading ? (
													<Spinner
														className="dynamic-table-spinner"
														animation="border"
														role="status"
														size="sm"
														style={{
															verticalAlign: "middle",
															width: "0.875rem",
															height: "0.875rem",
															marginLeft: 3,
														}}
													></Spinner>
												) : (
													""
												)}
											</button>
										</div>

										{countdownTAC && countdownPeriod == 0 && (
											<div>
												<button
													className="f-weight-500 mt-3 font12 text-redlight text-capitalize"
													style={{
														background: "none",
														border: "none",
														padding: 0,
														cursor: "pointer",
													}}
												>
													{t("DIDNT_RECEIVE_A_CODE_TRY_OTHER_METHOD")}
												</button>

												{attemp == 1 && (
													<div>
														<button
															className="t3-custom-light-btn"
															type="submit"
															onClick={() => (verifyState.button = 2)}
														>
															{t("RECEIVE_OTP_VIA_EMAIL")}
														</button>
													</div>
												)}
												{attemp == 2 && (
													<div>
														<button
															className="t3-custom-light-btn"
															type="button"
															onClick={() => setIsLiveChatOpen(true)}
														>
															{t("CONTACT_CUSTOMER_SERVICE")}
														</button>
													</div>
												)}
											</div>
										)}
									</form>
								)}
								{isUpdatePassword && (
									<form onSubmit={ResetPasswordHandleSubmit(submitNewPassword)}>
										<label>{t("ENTER_NEW_PASSWORD")}</label>
										<Input
											type="password"
											name="newPassword"
											id="newPassword"
											className="t3-custom-input-text"
											placeholder={t("NEW_PASSWORD")}
											autoComplete="off"
											ref={ResetPasswordRegister({
												required: true,
												minLength: {
													value: _MIN_PASSWORD_LENGTH,
													message: t("MIN_CHARACTERS", {
														length: _MIN_PASSWORD_LENGTH,
													}),
												},
												validate: (value) =>
													watch("confPassword") == "" ||
													value != watch("confPassword") ||
													"PASSWORD_MISMATCH",
											})}
											errors={ResetPasswordErrors}
										/>
										<label>{t("ENTER_CONFIRM_PASSWORD")}</label>
										<Input
											type="password"
											name="confPassword"
											id="confPassword"
											className="t3-custom-input-text"
											placeholder={t("CONFIRM_NEW_PASSWORD")}
											autoComplete="off"
											ref={ResetPasswordRegister({
												required: true,
												minLength: {
													value: _MIN_PASSWORD_LENGTH,
													message: t("MIN_CHARACTERS", {
														length: _MIN_PASSWORD_LENGTH,
													}),
												},
												validate: (value) =>
													watch("newPassword") == "" ||
													value != watch("newPassword") ||
													"PASSWORD_MISMATCH",
											})}
											errors={ResetPasswordErrors}
										/>
										<div>
											<button className="t3-custom-light-btn">
												{t("RESET_PASSWORD")}
											</button>
										</div>
									</form>
								)}
								{isPasswordUpdated && (
									<form
										onSubmit={PasswordUpdatedHandleSubmit(
											submitPasswordUpdated
										)}
									>
										<div className="t3-lr-success">
											<h2 className="t3-primary-text-color">
												{t("RESET_PASSWORD_SUCCESS")}
											</h2>
											<div className="t3-lr-button-box mt-3">
												<div
													onClick={() => {
														setIsModalVisible(!isModalVisible);
														setForgotPassword(false);
														setShowModalLogin(true);
													}}
												>
													<button className="t3-custom-light-btn">
														{t("CLOSE")}
													</button>
												</div>
											</div>
										</div>
									</form>
								)}
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};

export default Header;
