import React, { useState } from 'react';

const FallbackImage = ({ src, alt, fallbackSrc }) => {
  const [imageLoaded, setImageLoaded] = useState(true);
  const [imageError, setImageError] = useState(false);

  const handleLoad = () => {
    // console.log("Load")
    setImageLoaded(true);
    setImageError(false);
  };

  const handleError = () => {
    console.log("Error")
    setImageLoaded(false);
    setImageError(true);
  };

  return (
    <>
      {imageLoaded && !imageError  && src ? (
        <img
          src={src}
          alt={alt}
          onLoad={handleLoad}
          onError={handleError}
          className="img-100"
          style={{ display: imageLoaded ? 'block' : 'none' }}
        />
      ) : (
          <img src={fallbackSrc} alt="Fallback Image" className="img-100" />
      )}
    </>
  );
};

export default FallbackImage;
