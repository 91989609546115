import React from "react";

/**
 * Author: -
 */
const InfoBox = React.forwardRef((props, ref) => {
	const {
		name,
		className="",
		containerClassName = "",
		iconClassName = "",
		contentClassName = "",
		content="",
		type="default",
		...rest
	} = props;

	const typeConfig = {
		notice: {
		  iconColor: "#FF0000",
		  textColor: "#FF0000",
		  highlightColor: "#FF493F",
		  bgColor: "rgba(100, 0, 0, 0.3)"
		},
		danger: {
		  iconColor: "#FF0000",
		  textColor: "#FF0000",
		  highlightColor: "#FF493F",
		  bgColor: "rgba(100, 0, 0, 0.3)"
		},
		default: {
		  iconColor: "#FF0000",
		  textColor: "#FF0000",
		  highlightColor: "#FF493F",
		  bgColor: "rgba(100, 0, 0, 0.3)"
		}
	};

	const { iconColor, textColor, highlightColor, bgColor } = typeConfig[type] || typeConfig.default;

	return (
		<div className={className} style={rest.style}>
			<div className={`info-box ${containerClassName}`} style={{ 
					display: 'flex',
					backgroundColor: bgColor + " !important",
					padding: "10px",
					border: "1px solid " + highlightColor,
					borderRadius: "8px",
					textAlign: "left",
					gap: "10px",
					fontSize: "1rem",
					fontWeight: "400"
				}}>
				<div className="info-box-icon">
					{/* <i className={iconClassName}></i> */}
					<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M12.5 21.875C10.6458 21.875 8.83324 21.3252 7.29153 20.295C5.74982 19.2649 4.54821 17.8007 3.83863 16.0877C3.12906 14.3746 2.94341 12.4896 3.30514 10.671C3.66688 8.85246 4.55976 7.182 5.87088 5.87088C7.182 4.55976 8.85246 3.66688 10.671 3.30514C12.4896 2.94341 14.3746 3.12906 16.0877 3.83863C17.8007 4.54821 19.2649 5.74982 20.295 7.29153C21.3252 8.83324 21.875 10.6458 21.875 12.5C21.8723 14.9856 20.8837 17.3686 19.1262 19.1262C17.3686 20.8837 14.9856 21.8723 12.5 21.875ZM12.5 4.6875C10.9548 4.6875 9.44437 5.1457 8.15961 6.00415C6.87485 6.8626 5.87351 8.08274 5.2822 9.51029C4.69089 10.9378 4.53617 12.5087 4.83762 14.0241C5.13907 15.5396 5.88314 16.9317 6.97573 18.0243C8.06833 19.1169 9.46038 19.8609 10.9759 20.1624C12.4913 20.4638 14.0622 20.3091 15.4897 19.7178C16.9173 19.1265 18.1374 18.1252 18.9959 16.8404C19.8543 15.5556 20.3125 14.0452 20.3125 12.5C20.3102 10.4287 19.4864 8.44288 18.0218 6.97825C16.5571 5.51361 14.5713 4.68978 12.5 4.6875Z" fill={iconColor} />
						<path d="M12.5 15.625C12.2928 15.625 12.0941 15.5427 11.9476 15.3962C11.8011 15.2497 11.7188 15.051 11.7188 14.8438V7.8125C11.7188 7.6053 11.8011 7.40659 11.9476 7.26007C12.0941 7.11356 12.2928 7.03125 12.5 7.03125C12.7072 7.03125 12.9059 7.11356 13.0524 7.26007C13.1989 7.40659 13.2812 7.6053 13.2812 7.8125V14.8438C13.2812 15.051 13.1989 15.2497 13.0524 15.3962C12.9059 15.5427 12.7072 15.625 12.5 15.625Z" fill={iconColor} />
						<path d="M12.5 17.9688C12.9315 17.9688 13.2812 17.619 13.2812 17.1875C13.2812 16.756 12.9315 16.4062 12.5 16.4062C12.0685 16.4062 11.7188 16.756 11.7188 17.1875C11.7188 17.619 12.0685 17.9688 12.5 17.9688Z" fill={iconColor} />
					</svg>
				</div>
				<div className={"info-box-content " + contentClassName}>
					<span className="info-box-text" style={{ color: textColor }}>{content}</span>
				</div>
			</div>
		</div>
	);
});

export default InfoBox;
