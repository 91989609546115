import { themeIcons } from "config/theme";
import Deposit from "pages/template_1/transaction/Deposit";
import Downline from "pages/template_1/home/Downline";
import WithdrawalMethods from "pages/template_1/transaction/WithdrawalMethods";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import i18n from "localization/i18n";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import VIcon from "components/custom/Icon";
import { useDispatch, useSelector } from "react-redux";
import { showMessage, setShareReferralModal } from "redux/AppAction";
import { map } from "lodash";
import { InlineShareButtons } from "sharethis-reactjs";
import {
	ApiKey,
	ApiUrl,
	WebUrl,
	SessionKey,
	AlertTypes,
} from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine.js";
import {
	stringIsNullOrEmpty,
	isObjectEmpty,
	numberWithCurrencyFormat,
	createFormBody,
} from "../../util/Util";

const ShareReferralModal = (props) => {
	var { t } = useTranslation();
    var _history = useHistory();
	const isShareReferralModal = useSelector(
		(state) => state.appState.shareReferralModal
	);
	const memberData = useSelector((state) => state.appState.member);
	var _dispatch = useDispatch();
	const [registerUrl, setRegisterUrl] = useState("");
	const [uplineLoading, setUplineLoading] = useState(false);
    const _APPEND_REGISTER_URL = "/register?code=";

	useEffect(() => {
		if (memberData["referralCode"]) {
			getUpline(memberData["referralCode"]);
		}
	}, []);

	useEffect(() => {
		if (memberData["referralCode"]) {
			getUpline(memberData["referralCode"]);
		}
	}, [memberData["referralCode"]]);

	const socialMediaList = [
		{
			image: require("../../assets/img/template_1/share/ig.png"),
			text: "Instagram",
		},
		{
			image: require("../../assets/img/template_1/share/fb.png"),
			text: "Facebook",
		},
		{
			image: require("../../assets/img/template_1/share/tele.png"),
			text: "Telegram",
		},
		{
			image: require("../../assets/img/template_1/share/wa.png"),
			text: "Whatapps",
		},
	];

	async function getUpline(code) {
		// _dispatch(setBusy());
		setUplineLoading(true);
		let responseJson = await ApiEngine.get(
			ApiUrl._API_GET_UPLINE_BY_REFERRAL_CODE + "?code=" + code
		);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setRegisterUrl(window.location.origin + _APPEND_REGISTER_URL + code);
		} else {
			_dispatch(
				showMessage({
					type: AlertTypes._ERROR,
					content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
					onConfirm: () => _history.replace(WebUrl._URL_MAIN),
				})
			);
		}
		// _dispatch(setIdle());
		setUplineLoading(false);
	}

	const ShareButton = useMemo(() => {
		if (!stringIsNullOrEmpty(registerUrl)) {
			return (
				<InlineShareButtons
					config={{
						alignment: "center",
						color: "social",
						enabled: true,
                        font_size: 16,
						language: "en",
						networks: ["facebook", "twitter", "whatsapp"],
						padding: 12,
						radius: 4,
						size: 40,
						url: registerUrl,
					}}
				/>
			);
		} else {
			return null;
		}
	}, [registerUrl]);

	const closeBtn = (
		<div
		  className="close"
		  onClick={() => {
			_dispatch(setShareReferralModal(!isShareReferralModal));
		  }}
		>
		  <VIcon icon={themeIcons.close} className="vicon" />
		</div>
	  );

	return (
		<Modal
			id="t3-custom-modal"
			fade={false}
			isOpen={isShareReferralModal}
			className="modal-custom-fullscreen share-referral-modal"
			contentClassName={"t3-custom-modal"}
			backdrop={"static"}
			toggle={() => {
				_dispatch(setShareReferralModal(!isShareReferralModal));
			}}
			// fullscreen={"true"}
			//centered
		>
			<ModalHeader close={closeBtn}>
				<div>{t("SHARE_REFERRAL")}</div>
			</ModalHeader>
			<ModalBody>
          		<div className="t3-custom-modal-container pt-5">
				  {ShareButton}
				</div>
				<div className="text-center mt-5 mb-4">
					<button
						className="t3-custom-light-btn"
						type="submit"
						onClick={() => {
							// setShowModal(!showModal)
							_dispatch(setShareReferralModal(!isShareReferralModal));
						}}
					>
						{t("CLOSE")}
					</button>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default ShareReferralModal;
