import Cookies from "universal-cookie";
import { SessionKey } from "../util/Constant";

export const _SAVE_LANGUAGE_ID = "saveLanguageId";
export const _COOKIE = new Cookies();

/// <summary>
/// Author : -
/// </summary>
export const saveLanguageId = (id, retain) => {
	localStorage.setItem(SessionKey._LANGUAGE, id);
	localStorage.removeItem("setReportDate");
	_COOKIE.set(SessionKey._LANGUAGE, id);

	return async (dispatch) => {
		dispatch({
			type: _SAVE_LANGUAGE_ID,
			languageId: id,
			retain: retain,
		});
	};
};
