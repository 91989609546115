import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBusy, setIdle, bindCompanyIcon } from "../../redux/AppAction.js";
import { map, debounce, find, filter, upperCase } from "lodash";

const Loading = (props) => {
	var _dispatch = useDispatch();
	const companyIcons = useSelector((state) => state.appState.companyIcons);
	const [logo, setLogo] = useState();

	useEffect(() => {
		//bindCompanyIcon(_dispatch);
	}, []);

	useEffect(() => {
		if (companyIcons.length > 0) {
			getCompanyIcon();
		}
	}, [companyIcons]);

	async function getCompanyIcon() {
		_dispatch(setBusy());
		const logo = find(companyIcons, { iconCategory: "logo" });
		if (logo) {
			setLogo(logo.iconImage);
		}
		_dispatch(setIdle());
	}

	return (
		<>
			<div className="default-loading-logo mt-3">
				<img src={logo} />
			</div>
		</>
	);
};

export default Loading;
