import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
	ApiKey,
	ApiUrl,
	WebUrl,
	TransactionType,
	TransactionStatus,
} from "../../../util/Constant";
import ApiEngine from "../../../util/ApiEngine";
import {
	setBusy,
	setIdle,
	showMessage,
	showResponseMessage,
	bindCompanyIcon,
} from "../../../redux/AppAction";
import {
	stringIsNullOrEmpty,
	numberWithCurrencyFormat,
} from "../../../util/Util";
import { debounce, map, find, filter, upperCase, orderBy } from "lodash";
import { themeIcons } from "config/theme";
import Spinner from "react-bootstrap/Spinner";
import DateRangeFilter from "components/custom/DateRangeFilter";
import PaginationBar from "../../../components/custom/PaginationBar";

const TransactionHistory = () => {
	let _history = useHistory();
	let _location = useLocation();
	const _dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const [transactionDetailsActiveTaB, setTransactionDetailsActiveTaB] =
		useState(1);
	const [paginationPageNum, setPaginationPageNum] = useState(0);
	const [isResetCurrentPage, setIsResetCurrentPage] = useState(false);
	const [sortOrder, setSortOrder] = useState("");
	const [loadingFlag, setLoadingFlag] = useState(true);
	const [searchTransactionHistory, setSearchTransactionHistory] = useState([]);
	const [startDate, setStartDate] = useState(
		_location?.state?.date
			? moment(_location.state.date.split(t("TO"))[0], "DD-MM-YYYY")
			: moment().startOf("isoWeek")
	);
	const [endDate, setEndDate] = useState(
		_location?.state?.date
			? moment(_location.state.date.split(t("TO"))[1], "DD-MM-YYYY")
			: moment().endOf("day")
	);

	const [selectedFilterType, setSelectedFilterType] = useState(
		_location?.state?.date
			? _location.state.date
			: moment().startOf("isoWeek").format("DD-MM-YYYY") +
					t("TO") +
					moment().endOf("day").format("DD-MM-YYYY")
	);

	const filterTypeOnSelect = (value) => {
		setSelectedFilterType(value);
	};

	const scrollCategory = [
		{
			text: "DEPOSITS",
			transactionTypeId: 1,
		},
		{
			text: "WITHDRAWALS",
			transactionTypeId: 2,
		},
	];

	useEffect(() => {
		if (transactionDetailsActiveTaB != undefined) {
			getTransactionData();
		}
	}, [startDate, endDate, transactionDetailsActiveTaB]);

	useEffect(() => {
		if (isResetCurrentPage) {
			setSortOrder("");
		}
	}, [isResetCurrentPage]);

	useEffect(() => {
		if (sortOrder != "") {
			var transactionData = searchTransactionHistory;
			var sortedtransactionData = orderBy(
				transactionData,
				["amount"],
				sortOrder
			);

			setSearchTransactionHistory(sortedtransactionData);
		}
	}, [sortOrder]);

	async function getTransactionData(page, keyword) {
		setSearchTransactionHistory([]);
		setLoadingFlag(true);
		try {
			page = page ? page : 1;
			var start = (page - 1) * 10;

			setTransactionDetailsActiveTaB(transactionDetailsActiveTaB);
			var apiUrl = `${
				ApiUrl._API_GET_TRANSACTION_BY_MEMBER_DEPOSIT_AND_WITHDRAWAL +
				"?StartDate=" +
				moment(startDate).format("YYYY-MM-DD") +
				"&EndDate=" +
				moment(endDate).format("YYYY-MM-DD")
			}`;

			if (transactionDetailsActiveTaB != 0) {
				apiUrl += "&Type=" + transactionDetailsActiveTaB;
			}

			apiUrl = apiUrl + "&start=" + start + "&count=10";


			var responseJson = await ApiEngine.get(apiUrl);

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				setSearchTransactionHistory(
					responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY]
				);

				var totalCount = responseJson[ApiKey._API_DATA_KEY].totalCount;
				updatePageinationPageNum(Math.ceil(totalCount / 10));
			} else {
				_dispatch(
					showMessage(
						responseJson[ApiKey._API_SUCCESS_KEY],
						t(responseJson[ApiKey._API_MESSAGE_KEY])
					)
				);
			}
		} catch (err) {
			_dispatch(showResponseMessage(false, err));
		}
		setLoadingFlag(false);
		return;
	}

	function updatePageinationPageNum(pageValue) {
		setPaginationPageNum((prevValue) => pageValue);
	}

	return (
		<div className="t3-downline-modal-body pt-0 mb-4" id="deposit-id">
			<h3 className="t3-h3 text-left mt-0">{t("TRANSACTION_RECORD")}</h3>
			<DateRangeFilter
				// isOpen={isDateFilterOpen}
				startDate={startDate}
				endDate={endDate}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
				onSave={(start, end) => {
					setStartDate(start);
					setEndDate(end);
				}}
				options={true}
				initialType={selectedFilterType}
				// onClose={dateRangePickerOnClose}
				typeOnSelect={filterTypeOnSelect}
			/>

			<div className="t3-downline-status-table-tab">
				{scrollCategory &&
					scrollCategory.length &&
					map(scrollCategory, (item, index) => {
						return (
							<button
								key={index}
								className={
									transactionDetailsActiveTaB == item.transactionTypeId
										? "active"
										: ""
								}
								onClick={() => {
									setTransactionDetailsActiveTaB(item.transactionTypeId);
								}}
							>
								<div style={{ cursor: "pointer" }}>{t(item.text)}</div>
							</button>
						);
					})}
			</div>
			<div className="t3-downline-table-body">
				<div className="t3-table-container">
					<table className="t3-custom-table">
						<thead>
							<tr>
								<th>{t("DATE")}</th>
								<th>{t("AMOUNT")}</th>
								<th>{t("STATUS")}</th>
								<th>{t("DESCRIPTION")}</th>
							</tr>
						</thead>
						<tbody>
							{searchTransactionHistory &&
							searchTransactionHistory.length != 0 ? (
								searchTransactionHistory.map((data, index) => {
									var imgSrc = "";
									var transactionType = "";
									switch (data["transactionTypeId"]) {
										case TransactionType._DEPOSIT:
											imgSrc = themeIcons.card;
											transactionType = "DEPOSIT";
											break;
										case TransactionType._WITHDRAWAL:
											imgSrc = themeIcons.okup_withdraw;
											transactionType = "WITHDRAWAL";
											break;
										case TransactionType._TRANSFER:
											imgSrc = themeIcons.wallet;
											transactionType = "TRANSFER";
											break;
										default:
											imgSrc = themeIcons.settingsBank;
											transactionType = "";
											break;
									}

									var statusHtml = "";
									switch (data["approvalStatus"]) {
										case TransactionStatus._APPROVED:
											statusHtml = (
												<span className="font12 text-customgreen f-weight-500">
													{t("APPROVED")}
												</span>
											);
											break;
										case TransactionStatus._REJECTED:
											statusHtml = (
												<span className="font12 text-customred f-weight-500">
													{t("REJECTED")}
												</span>
											);
											break;
										case TransactionStatus._OPEN:
											statusHtml = (
												<span className="font12 text-customyellow f-weight-500">
													{t("PROCESSING")}
												</span>
											);
											break;
										default:
											statusHtml = (
												<span className="font12 text-customyellow f-weight-500">
													{t("PROCESSING")}
												</span>
											);
											break;
									}

									return (
										<tr key={index}>
											<td>
												{moment(data.dateTime, "DD/MM/YYYY, HH:mm:ss").format(
													"DD-MM-YYYY HH:mm:ss"
												)}
											</td>
											<td>
												{numberWithCurrencyFormat(parseFloat(data.amount), 2)}
											</td>
											<td>{statusHtml}</td>
											<td>
												{data["channel"] != ""
													? t(transactionType) + " - " + data["channel"]
													: t(transactionType)}
											</td>
										</tr>
									);
								})
							) : loadingFlag ? (
								<tr>
									<td colSpan={4}>
										<div
											style={{
												textAlign: "center",
												marginTop: "25px",
												marginBottom: "20px",
											}}
										>
											<Spinner
												animation="border"
												role="status"
												size="sm"
												style={{
													verticalAlign: "middle",
													width: "2rem",
													height: "2rem",
												}}
											>
												<span className="sr-only">{t("LOADING")}...</span>
											</Spinner>
										</div>
									</td>
								</tr>
							) : (
								<tr>
									<td colSpan={4}>{t("NO_DATA_FOUND")}</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
			{paginationPageNum > 1 && (
				<PaginationBar
					totalPages={paginationPageNum}
					retrieveDataRecords={getTransactionData}
					isResetCurrentPage={isResetCurrentPage}
					setIsChangeTab={setIsResetCurrentPage}
				/>
			)}
		</div>
	);
};

export default TransactionHistory;
