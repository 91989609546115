import { fetch } from 'whatwg-fetch';
import { InputValidationKey } from './Constant';

/// <summary>
/// Author : 
/// To Check if it is empty or null string
/// </summary>
export function stringIsNullOrEmpty(data) {
    return data == null || data == '';
}

/// <summary>
/// Author : 
/// Convert array into form that use to post data
/// </summary>
export function createFormBody(params) {
    let formBody = [];

    for (let property in params) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(params[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }

    formBody = formBody.join("&");
    return formBody;
}

/// <summary>
/// Author : 
/// Convert array into form that use to post data
/// </summary>
export function createMultiPartFormBody(params) {
    var formBody = new FormData();

    for (const [key, value] of Object.entries(params)) {
        formBody.append(key, value);
    }

    return formBody;
}

/// <summary>
/// Author : 
/// Used to Navigation to certain path
/// </summary>
export function navigateTo(history, url, state) {
    if (state !== undefined) {
        history.push({
            pathname: url,
            state: state
        });
    } else {
        history.push(url);
    }
}

/// <summary>
/// Author : 
/// </summary>
export const dataTableFilterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] !== null && typeof row[id] === 'string') {
        return (
            row[id] !== undefined ?
            String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
        )
    } else {
        return (
            String(row[filter.id]) === filter.value
        )
    }
}

/// <summary>
/// Author: -
/// </summary>
export function getValidationMessage(error) {
    var validationMsg = "";
    switch (error.type) {
        case InputValidationKey._REQUIRED_KEY:
            validationMsg = "INPUT_FIELD_REQUIRED";
            break;
        case InputValidationKey._MAX_LENGTH_KEY:
            validationMsg = "MAX_LENGTH_EXCEEDED";
            break;
        case InputValidationKey._MIN_LENGTH_KEY:
            validationMsg = "MIN_LENGTH_NO_MET";
            break;
        default:
            validationMsg = error.message
            break;
    }
    return validationMsg;
}

/// <summary>
/// Author: -
/// </summary>
export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/// <summary>
/// Author: -
/// </summary>
export function isObjectEmpty(data) {
    return Object.keys(data).length === 0;
}

/// <summary>
/// Author : 
/// </summary>
export async function imagePathToFileObject(imagePath) {
    const response = await fetch(imagePath);
    const blob = await response.blob();
    const file = new File([blob], imagePath);
    return file;
}

/// <summary>
/// Author : 
/// </summary>
export function formatNumber(data, decimalPlaces) {
    return parseFloat(data).toLocaleString(undefined, { minimumFractionDigits: decimalPlaces })
}

/// <summary>
/// Author : 
/// </summary>
export function arrayGroupByKey(array, key) {
    return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
            currentValue
        );

        return result;
    }, {});
};

/// <summary>
/// Author : 
/// </summary>
export function numberWithCurrencyFormat(value, decimal = 2, force = false) {
    const CURRENCY_DECIMAL = 2;

    
    if (decimal === 0) {
        return Math.round(value).toString().replace(/\d(?=(\d{3})+$)/g, '$&,');
    }


    var convertedNumber = parseFloat((Math.round(value * Math.pow(10, decimal))) / Math.pow(10, decimal)).toFixed(decimal).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,');

    if (decimal > CURRENCY_DECIMAL) {
        let afterDecimalNumber = convertedNumber.split('.');
        let decimalDiff = decimal - CURRENCY_DECIMAL;
        if (afterDecimalNumber.length == 2 && convertedNumber.lastIndexOf('0') == (convertedNumber.length - 1)) {
            let processNumber = afterDecimalNumber[1];
            let beginNumber = !force ? processNumber.length - 1 : processNumber;
            for (let i = beginNumber; (i > 0 && decimalDiff > 0); i--) {
                if (processNumber.charAt(i) == '0') {
                    processNumber = processNumber.substr(0, i) + processNumber.substr(i + 1);
                } else {
                    break;
                }
                decimalDiff--;
            }
            convertedNumber = afterDecimalNumber[0] + "." + processNumber;
        }
    }

    return convertedNumber
}

/// <summary>
/// Author : 
/// </summary>
export function ignoreDecimal(e) {
    const _KEYCODE_DECIMAL = 190;
    const _KEYCODE_E = 69;
    let keyCode = e.keyCode;

    if (keyCode == _KEYCODE_DECIMAL || keyCode == _KEYCODE_E) {
        e.preventDefault();
    }
}

export function formatPercent(v) {
    return `${v}%`
}

// CHRIS_LOCALE: Add locale definitions and functions
const VALID_LOCALES =  [ 'ab' , 'aa' , 'af' , 'ak' , 'sq' , 'am' , 'ar' , 'an' , 'hy' , 'as' , 'av' , 'ae' , 'ay' , 'az' , 'bm' , 'ba' , 'eu' , 'be' , 'bn' , 'bh' , 'bi' , 'bs' , 'br' , 'bg' , 'my' , 'ca' , 'km' , 'ch' , 'ce' , 'ny' , 'zh', 'zk', 'cu' , 'cv' , 'kw' , 'co' , 'cr' , 'hr' , 'cs' , 'da' , 'dv' , 'nl' , 'dz' , 'en' , 'eo' , 'et' , 'ee' , 'fo' , 'fj' , 'fi' , 'fr' , 'ff' , 'gd' , 'gl' , 'lg' , 'ka' , 'de' , 'ki' , 'el' , 'kl' , 'gn' , 'gu' , 'ht' , 'ha' , 'he' , 'hz' , 'hi' , 'ho' , 'hu' , 'is' , 'io' , 'ig' , 'id' , 'ia' , 'ie' , 'iu' , 'ik' , 'ga' , 'it' , 'ja' , 'jv' , 'kn' , 'kr' , 'ks' , 'kk' , 'rw' , 'kv' , 'kg' , 'ko' , 'kj' , 'ku' , 'ky' , 'lo' , 'la' , 'lv' , 'lb' , 'li' , 'ln' , 'lt' , 'lu' , 'mk' , 'mg' , 'ms' , 'ml' , 'mt' , 'gv' , 'mi' , 'mr' , 'mh' , 'ro' , 'mn' , 'na' , 'nv' , 'nd' , 'ng' , 'ne' , 'se' , 'no' , 'nb' , 'nn' , 'ii' , 'oc' , 'oj' , 'or' , 'om' , 'os' , 'pi' , 'pa' , 'ps' , 'fa' , 'pl' , 'pt' , 'qu' , 'rm' , 'rn' , 'ru' , 'sm' , 'sg' , 'sa' , 'sc' , 'sr' , 'sn' , 'sd' , 'si' , 'sk' , 'sl' , 'so' , 'st' , 'nr' , 'es' , 'su' , 'sw' , 'ss' , 'sv' , 'tl' , 'ty' , 'tg' , 'ta' , 'tt' , 'te' , 'th' , 'bo' , 'ti' , 'to' , 'ts' , 'tn' , 'tr' , 'tk' , 'tw' , 'ug' , 'uk' , 'ur' , 'uz' , 've' , 'vi' , 'vo' , 'wa' , 'cy' , 'fy' , 'wo' , 'xh' , 'yi' , 'yo' , 'za' , 'zu'];  // ISO 639-1

export function isValidLocale(locale) {
    return VALID_LOCALES.includes(locale);
}

// Checks if url has valid locale
export function hasValidLocale(pathname) {
    // Get locale from browser url
    const browserLocale = extractLocaleFromPathname(pathname);
    const regex = new RegExp(`^/${browserLocale}$`);
    
    // For condition where url ends with locale
    if(isValidLocale(browserLocale) && pathname.endsWith(`/${browserLocale}`) && pathname.match(regex)) {
        return `${browserLocale}`
    }

    // For condition where url contains locale
    const regex2 = new RegExp(`^/${browserLocale}(/|$)`)
    if(isValidLocale(browserLocale) && pathname.startsWith(`/${browserLocale}`) && pathname.match(regex2)) {
        return `${browserLocale}`
    } else {
        return false
    }
}

export function extractLocaleFromPathname(pathname) {
    // Split the pathname by slashes and filter out empty strings
    const pathSegments = pathname.split('/').filter(segment => segment.trim() !== '');
    // Check if there's at least one segment that could be the locale
    if (pathSegments.length > 0) {
        return pathSegments[0];
    } else {
        return null; // or a default locale, depending on your application's needs
    }
}

export function abbreviateNumber(number) {
    //parse the number just incase got "," or any symbols - john
    const parsedNumber = parseInt(number.replace(/[^0-9.-]+/g, ""));

    if (parsedNumber >= 1_000_000_000) {
      return (parsedNumber / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'b';
    } else if (parsedNumber >= 1_000_000) {
      return (parsedNumber / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'm';
    } else if (parsedNumber >= 1_000) {
      return (parsedNumber / 1_000).toFixed(1).replace(/\.0$/, '') + 'k';
    }
    return parsedNumber.toString();
}

// Utility function to adjust the font size
export const adjustFontSizeToFit = () => {
    // Select all the elements you want to adjust (you can customize this selector)
    const elements = document.querySelectorAll(".adjust-font-size");

    elements.forEach(element => {
        const maxWidth = element.clientWidth;
        const maxHeight = element.clientHeight;

        let fontSize = parseInt(window.getComputedStyle(element).fontSize, 10);

        // Decrease font size until the element fits within the container
        while (
            (element.scrollWidth > maxWidth || element.scrollHeight > maxHeight) &&
            fontSize > 1
        ) {
            fontSize -= 1;
            element.style.fontSize = `${fontSize}px`;
        }
    });
};