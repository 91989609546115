import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import store from "./ApplicationStore.js";
import App from "./app.jsx";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "./localization/i18n.js";

// css
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-quill/dist/quill.snow.css";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "simple-line-icons/css/simple-line-icons.css";
import "flag-icon-css/css/flag-icon.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./index.css";
import "./scss/react.scss";
import "bootstrap-social/bootstrap-social.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datetime/css/react-datetime.css";
import "react-datepicker/dist/react-datepicker.css";

//js
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

// ========================================

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
