import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { WebUrl } from "../../util/Constant";
import { useHistory, Link } from "react-router-dom";

const ContentHeader = ({
	enableBackButton,
	title,
	subTitle,
	topRightTitle,
	backButtonOnClick,
	escButtonOnClick,
	displayYellowAlert,
	topRightUrl,
	alertMsg,
}) => {
	const _history = useHistory();

	useEffect(() => {
		window.addEventListener("keydown", (e) => {
			if (e.key === "Escape") {
				_history.replace(WebUrl._URL_MAIN);
			}
		});
	}, []);

	return (
		<>
			<div className="width-100-p">
				<div className="top-title-right withdrwal-header">
					<div className={"content-header home-header shared-header"}>
						<div className="top-header">
							<div>
								{enableBackButton && (
									<button
										type="button"
										className="btn btn-icon"
										onClick={() => backButtonOnClick()}
									>
										<i className={"fa fa-angle-left"}></i>
									</button>
								)}
							</div>
						</div>
					</div>
					<a
						className="text-lblue1 text-underline"
						onClick={() => topRightUrl()}
						style={{ fontWeight: "bold", cursor: "pointer", minWidth:30 }}
					>
						{topRightTitle}
					</a>
				</div>
			</div>
			{displayYellowAlert && (
				<div className="mt-3 mb-3 reminder-yellow">
					<div className="first">
						<div className="first">
							<img
								src={require("../../assets/img/new_icons/yellow.png")}
								className="img-responsive"
								alt="icon"
							/>
						</div>
						<div>
							<p className="font14 mb-0 f-weight-500">{alertMsg}</p>
						</div>
					</div>
				</div>
			)}
			<div style={{ position: "relative", bottom: "10px" }}>
				<h3 className="mb-2">{title}</h3>
				{subTitle && <p className="font14 mb-4 text-lgray2">{subTitle}</p>}
			</div>
		</>
	);
};

ContentHeader.propTypes = {
	enableBackButton: PropTypes.bool,
	title: PropTypes.string.isRequired,
	subTitle: PropTypes.string,
	topRightTitle: PropTypes.string,
	backButtonOnClick: PropTypes.func,
	escButtonOnClick: PropTypes.func,
	displayYellowAlert: PropTypes.bool,
	topRightUrl: PropTypes.func,
	alertMsg: PropTypes.string,
};

ContentHeader.defaultProps = {
	enableBackButton: false,
	backButtonOnClick: () => window.history.go(-1),
	escButtonOnClick: () => {},
	topRightUrl: () => {},
	displayYellowAlert: false,
};

export default ContentHeader;
