import { createMultiPartFormBody } from "../util/Util";
import { ApiKey, ApiUrl } from "../util/Constant";
import {
	showResponseMessage,
	_SET_RESET_PASSWORD,
	_BIND_MEMBER,
	_BIND_MEMBER_WALLET_BALANCE
} from "./AppAction";
import ApiEngine from "../util/ApiEngine.js";
import { _SAVE_LANGUAGE_ID } from "./LanguageAction";

export const _LOGIN_SUCCESS = "LoginSuccess";
export const _LOGIN_FAILED = "LoginFailed";
export const _LOGOUT = "Logout";
export const _GET_SHOP_CREDIT = "GetShopCredit";
export const _UPDATE_USER_DATA = "UpdateUserData";
export const _LOGIN_MODAL = "LoginModal";

/// <summary>
/// Author :
/// expose login action as function to be called from classes
/// </summary>
export function performLogin(
	username,
	password,
	selectedLanguage
) {
	return async (dispatch, getState) => {
		dispatch({
			type: _LOGOUT,
		});
		let params = {
			username: username,
			password: password,
			selectedLanguage: selectedLanguage,
		};

		var responseJson = await ApiEngine.post(
			ApiUrl._API_LOGIN,
			createMultiPartFormBody(params)
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			var userData = responseJson[ApiKey._API_DATA_KEY]["userData"];
			if (window.ReactNativeWebView) {
				window.ReactNativeWebView.postMessage(
					JSON.stringify({
						action: "login",
						jwtToken: responseJson[ApiKey._API_DATA_KEY]["jwtToken"],
						userId: userData["userId"],
					})
				);
			}
			
			dispatch({
				type: _LOGIN_SUCCESS,
				userData: userData,
			});
		} else {
			dispatch({
				type: _LOGIN_FAILED,
				message: responseJson[ApiKey._API_MESSAGE_KEY],
			});
			dispatch(
				showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
			);
		}
	};
}
export function performLoginReturnMessage(
	username,
	password,
	selectedLanguage
) {
	return async (dispatch, getState) => {
		dispatch({
			type: _LOGOUT,
		});
		let params = {
			username: username,
			password: password,
			selectedLanguage: selectedLanguage,
		};

		var responseJson = await ApiEngine.post(
			ApiUrl._API_LOGIN,
			createMultiPartFormBody(params)
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			var userData = responseJson[ApiKey._API_DATA_KEY]["userData"];
			if (window.ReactNativeWebView) {
				window.ReactNativeWebView.postMessage(
					JSON.stringify({
						action: "login",
						jwtToken: responseJson[ApiKey._API_DATA_KEY]["jwtToken"],
						userId: userData["userId"],
					})
				);
			}

			dispatch({
				type: _LOGIN_SUCCESS,
				userData: userData,
			});
			dispatch({
				type: _SET_RESET_PASSWORD,
				isResetPassword: userData["isResetPassword"],
			});
				
			return {
				type: _LOGIN_SUCCESS,
				message: _LOGIN_SUCCESS
			}
		} else {
			dispatch({
				type: _LOGIN_FAILED,
				message: responseJson[ApiKey._API_MESSAGE_KEY],
			});
			return {
				type: _LOGIN_FAILED,
				message: responseJson[ApiKey._API_MESSAGE_KEY]
			}
		}
	};
}
/// <summary>
/// Author :
/// this function is to only update user status to login if it is already considered authenticated in cookie
/// </summary>
export const updateLoginUser = (userData) => {
	return {
		type: _LOGIN_SUCCESS,
		userData: userData,
	};
};

export const updateWalletBalance = (userData) => {
	
	return {
		type: _BIND_MEMBER_WALLET_BALANCE,
		walletBalance: userData.walletBalance + userData.bonusBalance,
		walletsDetails: {
			currentWalletBalance : userData.walletBalance,
			bonusWalletBalance :userData.bonusBalance,
			isPromoCleared :userData.isPromoCleared,
			isBonusWalletRestored : userData.isBonusWalletRestored,
			isValidForPromotion : userData.isAnyTransactionValidForPromo,
			isActivedPromoExist: userData.isActivedPromoExist,
			promoMaxWithdrawal: userData.promoMaxWithdrawal
		}
	};
};



/// <summary>
/// Author :
/// </summary>
export const updateUserData = () => {
	return async (dispatch) => {
		var responseJson = await ApiEngine.get(ApiUrl._API_UPDATE_USER_DATA);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			dispatch({
				type: _UPDATE_USER_DATA,
				userData: responseJson[ApiKey._API_DATA_KEY],
			});
		}
	};
};

/// <summary>
/// Author :
/// expose logout action as function to be called from classes
/// </summary>
export const performLogout = () => {
	return async (dispatch) => {
		var responseJson = await ApiEngine.post(ApiUrl._API_LOGOUT);


		await responseJson;
		if (window.ReactNativeWebView) {
			window.ReactNativeWebView.postMessage(
				JSON.stringify({ message: "Succesfully logged out.", code: "LOGOUT" })
			);
		}
		dispatch({
			type: _LOGOUT,
		});

		dispatch({
			type: _BIND_MEMBER,
			member: [],
			walletBalance: 0,
			walletsDetails: {
				currentWalletBalance: 0,
				bonusWalletBalance: 0,
				isPromoCleared: false,
				isBonusWalletRestored: false,
				isValidForPromotion: false,
				isActivedPromoExist: false,
				promoMaxWithdrawal: 0
			},
		});
	};
};

/// <summary>
/// Author :
/// this function is to only update user status to logout
/// </summary>
export const resetLogin = () => {
	return async (dispatch) => {
		dispatch({
			type: _LOGOUT,
		});
	};
};

export const forceLogout = () => {
	return async (dispatch) => {
		localStorage.removeItem("isOnload");

		dispatch(showResponseMessage(false, "FORCED_LOGOUT", true));
		dispatch({
			type: _LOGOUT,
		});
	};
};

/// <summary>
/// Author : -
/// </summary>
export const heartBeat = () => {
	return async (dispatch, getState) => {
		var responseJson = await ApiEngine.get(ApiUrl._API_IS_LOGGED_IN); //, createMultiPartFormBody(params));
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			dispatch(updateWalletBalance(responseJson[ApiKey._API_DATA_KEY]));
			dispatch(updateLoginUser(responseJson[ApiKey._API_DATA_KEY]));
		} else if (
			!responseJson[ApiKey._API_SUCCESS_KEY] &&
			responseJson[ApiKey._API_MESSAGE_KEY] == "FORCE_LOGOUT"
		) {
			dispatch(forceLogout());
		} else {
			dispatch(resetLogin());
		}
	};
};

export const setToggleLoginModal = (isToggleLoginModal) => {
	return (dispatch) => {
		dispatch({
			type: _LOGIN_MODAL,
			isToggleLoginModal: isToggleLoginModal,
		});
	};
};
