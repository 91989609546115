import { themeIcons } from "config/theme";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import VIcon from "components/custom/Icon";

import { useDispatch, useSelector } from "react-redux";
import { ApiKey, ApiUrl } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine.js";
import { showResponseMessage, bindMember, setMyProfileModal, setCompleteInfoModal } from "../../redux/AppAction.js";
import { find, isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import { createFormBody, stringIsNullOrEmpty } from "../../util/Util.js";
import { Spinner } from "react-bootstrap";


const CompleteInfoModal = ({member}) => {
    var _dispatch = useDispatch();
    var { t } = useTranslation();
    const completeInfoModal = useSelector((state) => state.appState.completeInfoModal);
    const companyIcons = useSelector((state) => state.appState.companyIcons);
    const [alertIcon, setAlertIcon] = useState("");
    const memberData = useSelector((state) => state.appState.member);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false)

    const {
		handleSubmit,
		register,
		control,
		watch,
		getValues,
		errors,
		clearErrors,
		reset,
		trigger,
	} = useForm();


    useEffect(()=>{
        getCompanyIcon();
    },[])


    async function getCompanyIcon() {
        const alert = find(companyIcons, { iconCategory: "alert" });
        if (alert) {
          setAlertIcon(alert.iconImage);
        }
    
    
    }

    async function onSubmit(data){
        setIsSubmitLoading(true)
        let params = {};

        if(data.phone){
            params['phoneNumber'] = data.phone
        }

        if(data.email){
            params['email'] = data.email
        }


        var responseJson = await ApiEngine.post(
            ApiUrl._API_MEMBER_UPDATE_PROFILE,
            createFormBody(params)
          );
      
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
           await bindMember(_dispatch);
            _dispatch(
              showResponseMessage(
                responseJson[ApiKey._API_SUCCESS_KEY],
                responseJson[ApiKey._API_MESSAGE_KEY]
              )
            );
            setIsSubmitLoading(false)

            _dispatch(setCompleteInfoModal(false))
          }
          else{
            setIsSubmitLoading(false)

          }

      
    }

    const closeBtn = (
		<div
			className="close"
			onClick={() => _dispatch(setCompleteInfoModal(false))}
		>
			<VIcon icon={themeIcons.close} className="vicon" />
		</div>
	  );
    return (
        <Modal 
            fade={false}
            id="t3-custom-modal"
            isOpen={completeInfoModal}
            contentClassName={"t3-custom-modal"}

            toggle={() => {
                _dispatch(setCompleteInfoModal(!completeInfoModal))
            }}
            centered
        >
            <ModalHeader close={closeBtn}>	
                Notice
            </ModalHeader>
            <ModalBody>
                <div className="t3-custom-modal-container">
                    <div className="custom-body">
                        <div className="text-center">
                            <img
                                className="profile-sweet-alert-icon"
                                src={alertIcon}
                                alt="Custom Icon"
                            />
                            <div>{t("OOPS")}</div>
                            <div>{t("COMPLETE_REQUIRED")}</div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="t3-lr-form">
                                {
                                    !memberData?.phoneNumber && isEmpty(memberData?.phoneNumber) &&
                                    (
                                        <div className="t3-input-container mt-3">
                                            <label>{t("MOBILE_NUMBER")}</label>
                                            <div>
                                            <input
                                                    name="phone"
                                                    className="t3-custom-input-text"
                                                    ref={register({
                                                        required: t('ENTER_PHONE_NUMBER'),
                                                        minLength: {
                                                        value: 8,
                                                        message: t("MIN_CHARACTERS", { length: 8 }),
                                                        },
                                                        maxLength: {
                                                        value: 11,
                                                        message: t("MAX_CHARACTERS", { length: 11 }),
                                                        },
                                                        pattern: {
                                                        value: /^[0-9]+$/g,
                                                        message:
                                                            t("MIN_CHARACTERS", { length: 8 }) +
                                                            " " +
                                                            t("AND") +
                                                            " " +
                                                            t("ONLY_NUMBERS"),
                                                        },
                                                    })}
                                                    errors={errors}
                                                    onKeyDown={(e) => {
                                                        const key = e.key;
                                                        const allowedKeys = [
                                                        "Backspace",
                                                        "ArrowLeft",
                                                        "ArrowRight",
                                                        "Delete",
                                                        "Tab",
                                                        ];
        
                                                        if (
                                                        !/[0-9]/.test(key) &&
                                                        !allowedKeys.includes(key)
                                                        ) {
                                                        e.preventDefault();
                                                        }
                                                    }}
                                                    // onChange={(e) => onchange(e)}
                                                />
                                    
                                                {errors.phone && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.phone.message)}
                                                    </div>
                                                    )}
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    !memberData?.email && isEmpty(memberData?.email) && (
                                        <div className="t3-input-container mt-3">
                                        <label>{t("Email")}</label>
                                        <div>
                                            <input
                                                className="t3-custom-input-text"
                                                type="text"
                                                name="email"
                                                id="email"
                                                ref={register({
                                                    required: t('ENTER_EMAIL'),
                      
                                                    pattern: {
                                                      value:
                                                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                      message: t("INVALID_EMAIL"),
                                                    },
                                                  })}
                                                autoComplete="off"
                                                errors={errors}
    
                                            />
                                            {errors.email && (
                                                <div className="invalid-feedback">
                                                    {t(errors.email.message)}
                                                </div>
                                                )}
                                        </div>
                                    </div>
                                    )
                                }


                                <div className="text-center mt-3">
                                    <button className="t3-custom-light-btn" disabled={isSubmitLoading}>
                                    {isSubmitLoading ? (
                                        <Spinner
                                            className="dynamic-table-spinner"
                                            animation="border"
                                            role="status"
                                            size="sm"
                                            style={{
                                            verticalAlign: "middle",
                                            width: "1.5rem",
                                            height: "1.5rem",
                                            marginLeft: 3,
                                            }}
                                        ></Spinner>
                                        ) : (
                                        t("SUBMIT")
                                    )}
                                    </button>
                                </div>

                            </div>

                        </form>
                        

                    </div>
                    
                   
                </div>
            </ModalBody>
        </Modal>

    )
};

export default CompleteInfoModal;