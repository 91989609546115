import React from 'react'
import { stringIsNullOrEmpty } from '../../util/Util';
  

class ProviderIconDisplay {
  constructor({imgClassName, imgSrc, imgAlt }) {
      this.imgClassName = imgClassName;
      this.imgSrc = imgSrc;
      this.imgAlt = imgAlt;
  }
}

const ProviderIcon = React.forwardRef((props, ref) => {
  const {
    containerClassName,
    imgLightMode,
    imgDarkMode,
    imgAlt
  } = props

  return (
    <div className={containerClassName}>
				{imgLightMode && !stringIsNullOrEmpty(imgLightMode.imgSrc) && (
        <img
          src={imgLightMode.imgSrc}
          className={`${imgLightMode.imgClassName} provider-logo-light`}
          alt={imgAlt}
          draggable={false}
        />
        )}
    
       {imgDarkMode && !stringIsNullOrEmpty(imgDarkMode.imgSrc) && (
        <img
          src={imgDarkMode.imgSrc}
          className={`${imgDarkMode.imgClassName} provider-logo-dark`}
          alt={imgAlt}
          draggable={false}
        />
      )}
		</div>
  )
})

export default ProviderIcon
export { ProviderIconDisplay };
