import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { WebUrl, ApiUrl, ApiKey } from "../../../util/Constant";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../../util/ApiEngine";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  showResponseMessage,
  setBusy,
  setIdle,
  setY,
  setPageSize,
} from "../../../redux/AppAction";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../../util/Util";
import { map } from "lodash";
import Input from "../../../components/custom/Input";
import classnames from "classnames";
import StyledSelect from "../../../components/custom/StyledSelect";
import { useForm, Controller } from "react-hook-form";
import ContentHeader from "../../../components/contentheader/header";

const DownlineDetails = (props) => {
  const { username, setShowDownlineDetail, showDownlineDetail, setShowDownlineList, setShowDownlineDetailTab } = props;
  var _history = useHistory();
  var { t } = useTranslation();
  var _dispatch = useDispatch();
  var _location = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  const [downlineData, setDownlineData] = useState({});
  const [referralUsed, setReferralUsed] = useState("");
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [nickname, setNickname] = useState("");
  const [referralCodeDetail, setReferralCodeDetail] = useState([]);
  const [referralGroupOptions, setReferralGroupOptions] = useState([]);
  const [selectedReferralGroup, setSelectedReferralGroup] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [productCommRate, setProductCommRate] = useState([]);

  const [editDownlineShow, setEditDownlineShow] = useState(false);
  const { register, handleSubmit, errors, setValue, watch, control, trigger } =
    useForm();
  const {
    register: registerNickname,
    handleSubmit: handleSubmitNickname,
    errors: errorsNickname,
    setValue: setValueNickname,
    watch: watchNickname,
    control: controlNickname,
    trigger: triggerNickname,
  } = useForm();

  useEffect(() => {
    if (username) {
      init();
      _dispatch(setPageSize(_location["scrollIndex"]));
    } else {
      _history.goBack();
    }
  }, [username]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  async function init() {
    _dispatch(setBusy());
    try {
      var responseJson = await ApiEngine.get(
        `${ApiUrl._API_GET_MEMBER_DETAILS}?memberdownline=${username}`
      );

      if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }

      setDownlineData(responseJson[ApiKey._API_DATA_KEY]);
      setNickname(responseJson[ApiKey._API_DATA_KEY].referralNickname);

      const referralCodeId = responseJson[ApiKey._API_DATA_KEY].referralCodeId;
      getReferralDetail(referralCodeId);

      var responseCatJson = await ApiEngine.get(
        ApiUrl._API_GET_PRODUCT_CATEGORY
      );
      if (responseCatJson[ApiKey._API_SUCCESS_KEY]) {
        let categoryData = responseCatJson[ApiKey._API_DATA_KEY];
        setCategories(categoryData);
        setActiveTab(categoryData[0]["id"]);
      }

      let tempProducts = [];

      getProductComRate();

      //var responseProductJson = await ApiEngine.get(
      //	ApiUrl._API_GET_PRODUCT_WITH_MAX_RATE
      //);

      //if (responseProductJson[ApiKey._API_SUCCESS_KEY]) {
      //	let productData = responseProductJson[ApiKey._API_DATA_KEY];

      //	productData.map((product) => {
      //		tempProducts.push({
      //			productId: product.productId,
      //			productName: product.productName,
      //			title: product.productName,
      //			categoryName: product.categoryName,
      //			maxBonus: product.maxBonus,
      //			maxClubFee: product.maxClubFee,
      //			maxCommission: product.maxCommission,
      //		});
      //	});

      //	setProducts(tempProducts);
      //}
    } catch (errorMessage) {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    } finally {
      _dispatch(setIdle());
    }
  }

  async function getReferralDetail(id) {
    let apiUrl = ApiUrl._API_GET_MEMBER_REFERRAL_CODES;

    if (!stringIsNullOrEmpty(id)) {
      apiUrl += "?id=" + id;
    }
    let data = [];
    let dataOptions = [];
    let responseJson = await ApiEngine.get(apiUrl);
    if (
      responseJson[ApiKey._API_SUCCESS_KEY] &&
      responseJson[ApiKey._API_DATA_KEY].length > 0
    ) {
      data = responseJson[ApiKey._API_DATA_KEY][0];
      dataOptions = responseJson[ApiKey._API_DATA_KEY];
      setReferralCodeDetail(data);
      setReferralGroupOptions(
        dataOptions.map((x) => {
          return { label: x.name + "(" + x.code + ")", value: x.id };
        })
      );
    }
    return data;
  }

  async function updateDownline() {
    _dispatch(setBusy());
    var responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_MEMBER_DOWNLINE +
      "?memberdownline=" +
      username +
      "&nickname=" +
      nickname +
      "&referralId=" +
      selectedReferralGroup
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      init();
      setEditDownlineShow(false);
    }
    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        t(responseJson[ApiKey._API_MESSAGE_KEY])
      )
    );
    _dispatch(setIdle());
  }

  async function getProductComRate() {
    let apiUrl = ApiUrl._API_PRODUCT_COMM_RATE;
    let data = [];
    let responseJson = await ApiEngine.get(apiUrl);
    if (
      responseJson[ApiKey._API_SUCCESS_KEY] &&
      responseJson[ApiKey._API_DATA_KEY].length > 0
    ) {
      data = responseJson[ApiKey._API_DATA_KEY].filter(
        (x) => x.status === true
      );

      setProductCommRate(data);
    }
  }

  async function handleOnClick() {
    setEditDownlineShow(true);
    getReferralDetail();
    setSelectedReferralGroup(
      Object.keys(referralCodeDetail).length > 0 ? referralCodeDetail.id : -1
    );
  }

  return (
    <>
      <div className="mb-5" id="Downline-details">
        {!editDownlineShow &&
          <div>
            <div className="top-title-right mb-3">
              <div className="content-header home-header shared-header modern-header">
                <div className="top-header">
                  <div>
                    <div style={{ display: "flex" }}>
                      <button
                        type="button"
                        className="btn btn-icon"
                        onClick={() => {
                          setShowDownlineDetail(false);
                          setShowDownlineList(true);
                          setShowDownlineDetailTab(true);
                        }}
                      >
                        <i className="fa fa-angle-left"></i>
                      </button>
                      <h5 className="mb-4">{t("DOWNLINE_DETAIL")}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="all-flex-box-gray position-relative mt-3 earn-box2 mb-3 ">
                <div className="downline-username-container">
                  <div className="font14 text-lgray5">{t("USERNAME")}</div>
                  <div className="font14 f-weight-500">
                    {downlineData["username"]}
                  </div>
                </div>
                <div>
                  <button type="submit" className="btn btn-custom-small" onClick={() => {
                    setEditDownlineShow(true);

                  }}>
                    {t("EDIT_DETAIL")}
                  </button>
                </div>
              </div>
              <div className="all-flex-box-gray-50 ">
                <div>
                  <div className="font12 text-lgray6">{t("CONTACT_NO")}</div>
                  <div className="font14 f-weight-500">
                    {downlineData["phoneNumber"]}
                  </div>
                </div>
                <div>
                  <div className="font12 text-lgray6">{t("CREATED_DATE")}</div>
                  <div className="font14 f-weight-500">
                    {downlineData["createdDate"]}
                  </div>
                </div>
              </div>
              <div className="all-flex-box-gray-50 mt-3">
                <div style={{ width: "100%" }}>
                  <div className="font12 text-lgray6">{t("REMARK")}</div>
                  <div className="font14 f-weight-500">
                    {downlineData["referralNickname"] != ""
                      ? downlineData["referralNickname"]
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        {editDownlineShow &&
          <div>
            <div className="top-title-right mb-3">
              <div className="content-header home-header shared-header modern-header">
                <div className="top-header">
                  <div>
                    <div style={{ display: "flex" }}>
                      <button
                        type="button"
                        className="btn btn-icon"
                        onClick={() => {
                          setEditDownlineShow(false)
                        }}
                      >
                        <i className="fa fa-angle-left"></i>
                      </button>
                      <h5 className="mb-4">{t("EDIT_DOWNLINE_DETAIL")}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="all-flex-box-gray position-relative mt-3 earn-box2 mb-3 ">
                <div className="downline-username-container">
                  <div className="font14 text-lgray5">{downlineData["username"]}</div>
                  <div className="font14 f-weight-500">
                    {t("CONTACT_NO")} : {downlineData["phoneNumber"]}
                  </div>
                </div>
              </div>
              <form
                onSubmit={handleSubmitNickname(updateDownline)}
                className="margin-bottom-0 "
              >
                <div className="font12">{t("REMARK")}</div>
                <Input
                  id="nickname"
                  name="nickname"
                  className="t3-custom-input-text mt-2"
                  value={nickname}
                  onChange={(e) => {
                    setNickname(e.target.value);
                  }}
                  autoFocus
                />
                {errorsNickname.nickname && (
                  <div className="invalid-feedback">
                    {t(errorsNickname.nickname.message)}
                  </div>
                )}

                <div className="t3-lr-button-box mt-5">
                  <div>
                    <button
                      className="t3-custom-light-btn"
                      type="submit"
                      style={{ cursor: "pointer" }}
                    >
                      {t("SAVE_CHANGES")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default DownlineDetails;
