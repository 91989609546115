import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../../util/Constant";
import { useTranslation , Trans} from "react-i18next";
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";
import { useForm, Controller } from "react-hook-form";
import ApiEngine from "../../../util/ApiEngine.js";
import { ModalBody, Spinner } from "reactstrap";
import { map, find, debounce, orderBy } from "lodash";
import {
    setBusy,
    setIdle,
    showMessage,
    showResponseMessage,
    bindCompanyIcon,
} from "../../../redux/AppAction";
import {
    stringIsNullOrEmpty,
    numberWithCurrencyFormat,
} from "../../../util/Util";
import DownlineDetails from "../../template_1/home/DownlineDetails";
import PaginationBar from "../../../components/custom/PaginationBar";

const DownlineKPI = (props) => {
    const { setShowDownlineDetailTab } = props;
    var { t } = useTranslation(LanguageKey._PRIMARY);
    var _history = useHistory();
    var _dispatch = useDispatch();
    var _location = useLocation();
    const { register, handleSubmit, errors, setValue, watch, control, trigger } =
        useForm();

    const [activeMemberCount, setActiveMemberCount] = useState("");
    const [inactiveMemberCount, setInactiveMemberCount] = useState("");

    const [paginationPageNum, setPaginationPageNum] = useState(0);

    const [loadingDetails, setLoadingDetails] = useState(false);
    const [showDownlineDetail, setShowDownlineDetail] = useState(false);
    const [username, setUsername] = useState("");
    const [showDownlineList, setShowDownlineList] = useState(true)
    const [loadingFlag, setLoadingFlag] = useState(true);
    const [searchL1DownlineMemberData, setSearchL1DownlineMemberData] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [isResetCurrentPage, setIsResetCurrentPage] = useState(false);
    const [sortOrder, setSortOrder] = useState("");

    const [downlineStatusActiveTab, setDownlineStatusActiveTab] = useState(1);
    const scrollCategory = [
        {
            text: "ACTIVE_DOWNLINES",
            downlineStatusTabId: 1,
        },
        {
            text: "NOT_ACTIVE_DOWNLINES",
            downlineStatusTabId: 2,
        },
    ];

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (downlineStatusActiveTab != undefined) {
            getDownlineMemberData();
        }
    }, [downlineStatusActiveTab]);

    useEffect(() => {
        if (isResetCurrentPage) {
            setSortOrder("");
        }
    }, [isResetCurrentPage]);

    useEffect(() => {
        if (sortOrder != "") {
            var downlineMemberData = searchL1DownlineMemberData;
            var sortedDownlineMemberData = orderBy(downlineMemberData, ["totalDeposit"], sortOrder);

            setSearchL1DownlineMemberData(sortedDownlineMemberData);
        }
    }, [sortOrder]);

    async function init() {
        await getDownlineMemberStatusCount();
    }

    async function getDownlineMemberStatusCount() {
        setLoadingDetails(true);

        var responseJson = await ApiEngine.get(ApiUrl._API_GET_DOWNLINE_MEMBER_STATUS_COUNT);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var totalActiveMemberCount = responseJson[ApiKey._API_DATA_KEY].totalActiveMemberCount;
            var totalInactiveMemberCount = responseJson[ApiKey._API_DATA_KEY].totalInactiveMemberCount;

            setActiveMemberCount(totalActiveMemberCount);
            setInactiveMemberCount(totalInactiveMemberCount);
        }

        setLoadingDetails(false);
    }

    async function getDownlineMemberData(page, keyword) {
        setSearchL1DownlineMemberData([]);
        setSortOrder("");
        setLoadingFlag(true);
        try {
            var memberDownlineStatus = downlineStatusActiveTab === 1 ? "activeMember" : "inactiveMember" ;
            page = page ? page : 1;
            var start = (page - 1) * 10;

            var apiUrl = ApiUrl._API_GET_USER_MEMBER_LIST_LATEST +
                "?directUserOnly=true&memberDownlineStatus=" + memberDownlineStatus + "&start=" + start + "&count=10&keyword=";

            if (!stringIsNullOrEmpty(keyword))
                apiUrl = apiUrl + keyword;

            var responseJson = await ApiEngine.get(apiUrl);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setSearchL1DownlineMemberData(responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY]);

                var totalCount = responseJson[ApiKey._API_DATA_KEY].totalCount;
                updatePageinationPageNum(Math.ceil(totalCount / 10));

            } else {
                _dispatch(
                    showMessage(
                        responseJson[ApiKey._API_SUCCESS_KEY],
                        t(responseJson[ApiKey._API_MESSAGE_KEY])
                    )
                );
            }
        } catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
        setLoadingFlag(false);
        return;
    }

    function updatePageinationPageNum(pageValue) {
        setPaginationPageNum(prevValue => pageValue);
    }

    const getText = async (e) => {
        getDownlineMemberData(1, e.target.value);
        setIsResetCurrentPage(true);
        setSearchKeyword(e.target.value);
    };

    async function clearSearchField() {
        document.getElementById('searchField').value = "";
        setSearchKeyword("");
    }

    const handleSort = () => {
        setSortOrder(prevOrder => {
            if (prevOrder === "")
                return "asc";

            return prevOrder === "asc" ? "desc" : "asc";
        });
    };

    return (
        <div className="t3-downline-modal-body mb-4" id="deposit-id">
            {showDownlineDetail && (
                <div>
                    <DownlineDetails username={username} setShowDownlineDetail={setShowDownlineDetail} showDownlineDetail={showDownlineDetail} setShowDownlineList={setShowDownlineList} setShowDownlineDetailTab={setShowDownlineDetailTab} />
                </div>
            )}
            {showDownlineList &&
                <div >
                    <div className="reports-stats-box mb-3">
                        <div className="ro-box-1-1">
                            <div className="earn-stat-title mb-1">
                                <div className="first-title">{t("TOTAL_ACTIVE_DOWNLINES")}</div>
                            </div>
                            {loadingDetails ? (
                                <Spinner
                                    animation="border"
                                    role="status"
                                    size="sm"
                                    style={{
                                        verticalAlign: "middle",
                                        width: "1.3rem",
                                        height: "1.3rem",
                                        margin: "5px 0px",
                                        paddingTop: "6px",
                                    }}
                                ></Spinner>
                            ) : (
                                <div className="earn-stat-number mb-1">{activeMemberCount}</div>
                            )}
                        </div>

                        <div className="ro-box-1-1">
                            <div className="earn-stat-title mb-1">
                                <div className="first-title">{t("TOTAL_NOT_ACTIVE_DOWNLINES")}</div>
                            </div>
                            {loadingDetails ? (
                                <Spinner
                                    animation="border"
                                    role="status"
                                    size="sm"
                                    style={{
                                        verticalAlign: "middle",
                                        width: "1.3rem",
                                        height: "1.3rem",
                                        margin: "5px 0px",
                                        paddingTop: "6px",
                                    }}
                                ></Spinner>
                            ) : (
                                <div className="earn-stat-number mb-1">{inactiveMemberCount}</div>
                            )}
                        </div>
                    </div>
                    <div className="mt-3">
                        <h5>{t("DOWNLINE_L1_KPIS")}</h5>
                    </div>
                    <div className="t3-downline-status-table-tab">
                        {scrollCategory &&
                            scrollCategory.length &&
                            map(scrollCategory, (item, index) => {
                                return (
                                    <button
                                        key={index}
                                        className={
                                            downlineStatusActiveTab == item.downlineStatusTabId
                                                ? "active"
                                                : ""
                                        }
                                        onClick={() => {
                                            setDownlineStatusActiveTab(item.downlineStatusTabId);
                                            setIsResetCurrentPage(true);
                                            clearSearchField();
                                        }}
                                    >
                                        <div

                                            style={{ cursor: "pointer" }}
                                        >
                                            {t(item.text)}
                                        </div>
                                    </button>
                                );
                            })}
                    </div>
                    {downlineStatusActiveTab == 2 && 
                        <div className="downline-reminder-red">
                            <div>
                                <p className="text-left font14 mb-0 f-weight-500">
                                    <Trans i18nKey="DOWNLINE_NOT_ACTIVE_MESSAGE"></Trans>
                                </p>
                            </div>
                        </div>
                    }
                    <div className="search-box mt-3">
                        <div className="search-with-icon-box">
                            <div className="search-icon">
                                <VIcon
                                    icon={themeIcons.search}
                                    className="theme-icon-size-20"
                                />
                            </div>
                            <input
                                type="text"
                                id="searchField"
                                placeholder={t("SEARCH_DONWLINE_USERNAME")}
                                // defaultValue={_savedData}
                                onInput={debounce((e) => getText(e), 500)}
                            />
                        </div>
                    </div>
                    <div className="t3-downline-table-body">
                        <div className="t3-table-container">
                            <table className="t3-custom-table">
                                <thead>
                                    <tr>
                                        <th>{t("USERNAME")}</th>
                                        <th>{t("DOWNLINE_CONTACT")}</th>
                                        <th>{t("REGISTER_DATE")}</th>
                                        <th className="sortable-header" onClick={handleSort}>
                                            <span className="text">
                                                {t("DEPOSIT")}
                                            </span>
                                            <span className="arrows">
                                                <span className={"arrow" + (sortOrder === "" ? " active" : sortOrder === "asc" ? " active" : "")}> {"<"}</span>
                                                <span className={"arrow" + (sortOrder === "" ? " active" : sortOrder === "desc" ? " active" : "")}> {">"}</span>
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {searchL1DownlineMemberData &&
                                        searchL1DownlineMemberData.length != 0 ? (
                                            searchL1DownlineMemberData.map((data, index) => {
                                                return (
                                                    <tr
                                                        onClick={() => {
                                                            setUsername(data.username);
                                                            setShowDownlineDetail(true);
                                                            setShowDownlineList(false);
                                                            setShowDownlineDetailTab(false);
                                                        }}
                                                        style={{ cursor: "pointer" }}
                                                        key={index}
                                                    >
                                                        <td className="downline-table-username">
                                                            {data.username}
                                                        </td>
                                                        <td>
                                                            {data.phoneNumber}
                                                        </td>
                                                        <td>
                                                            {data.createdDate}
                                                        </td>
                                                        <td>
                                                            {numberWithCurrencyFormat(parseFloat(data.totalDeposit), 2)}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                        loadingFlag ? (
                                          <tr>
                                            <td colSpan={4}>
                                              <div
                                                style={{
                                                  textAlign: "center",
                                                  marginTop: "25px",
                                                  marginBottom: "20px",
                                                }}
                                              >
                                                <Spinner
                                                  animation="border"
                                                  role="status"
                                                  size="sm"
                                                  style={{
                                                    verticalAlign: "middle",
                                                    width: "2rem",
                                                    height: "2rem",
                                                  }}
                                                >
                                                  <span className="sr-only">{t("LOADING")}...</span>
                                                </Spinner>
                                              </div>
                                            </td>
                                          </tr>
                                        ) : (
                                            <tr>
                                                <td colSpan={4}>
                                                   {t("NO_DATA_FOUND")}
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {paginationPageNum > 1 && <PaginationBar totalPages={paginationPageNum} retrieveDataRecords={getDownlineMemberData} searchKeyword={searchKeyword} isResetCurrentPage={isResetCurrentPage} setIsChangeTab={setIsResetCurrentPage} />}
                </div>
            }
        </div>
    )
};

export default DownlineKPI;