import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
	ApiKey,
	ApiUrl,
	WebUrl,
	BetStatus,
	LanguageKey,
	_TICKET_STATUS,
} from "../../../util/Constant";
import ApiEngine from "../../../util/ApiEngine";
import { useForm, Controller } from "react-hook-form";
import { Spinner } from "reactstrap";
import {
	setBusy,
	setIdle,
	showMessage,
	showResponseMessage,
	bindCompanyIcon,
} from "../../../redux/AppAction";
import {
	stringIsNullOrEmpty,
	numberWithCurrencyFormat,
} from "../../../util/Util";
import { map, orderBy } from "lodash";
import DateRangeFilter from "components/custom/DateRangeFilter";
import PaginationBar from "../../../components/custom/PaginationBar";

const GameHistory = () => {
	let _history = useHistory();
	let _location = useLocation();
	const _dispatch = useDispatch();
	const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
	const [paginationPageNum, setPaginationPageNum] = useState(0);
	const [isResetCurrentPage, setIsResetCurrentPage] = useState(false);
	const [sortOrder, setSortOrder] = useState("");
	const [loadingFlag, setLoadingFlag] = useState(true);
	const [gameHistoryData, setGameHistoryData] = useState([]);
	const [products, setProducts] = useState([]);
	const { control, watch, setValue } = useForm();
	const [queryProduct, setQueryProduct] = useState("");
	const [totalProducts, setTotalProducts] = useState(0);
	const [searchKeyword, setSearchKeyword] = useState("");
	const [loadingSearchData, setLoadingSearchData] = useState(false);
	const [recordUrl, setRecordUrl] = useState("");
	const [selectedOption, setSelectedOption] = useState({});
	const [optionClicked, setOptionClicked] = useState(false);
	const [dateSelectedOption, setDateSelectedOption] = useState({});
	const [chgOption, setchgOption] = useState();

	const [startDate, setStartDate] = useState(
		_location?.state?.date
			? moment(_location.state.date.split(t("TO"))[0], "DD-MM-YYYY")
			: moment().startOf("isoWeek")
	);
	const [endDate, setEndDate] = useState(
		_location?.state?.date
			? moment(_location.state.date.split(t("TO"))[1], "DD-MM-YYYY")
			: moment().endOf("day")
	);
	const [selectedFilterType, setSelectedFilterType] = useState(
		_location?.state?.date
			? _location.state.date
			: moment().startOf("isoWeek").format("DD-MM-YYYY") +
					t("TO") +
					moment().endOf("day").format("DD-MM-YYYY")
	);

	const filterTypeOnSelect = (value) => {
		setSelectedFilterType(value);
	};

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		setSearchKeyword("");
	}, []);

	useEffect(() => {
		let startDateMoment = dateSelectedOption.from
			? dateSelectedOption.from
			: moment();
		let endDateMoment = dateSelectedOption.to
			? dateSelectedOption.to
			: moment();

		if (startDate === undefined || optionClicked) {
			setStartDate(startDateMoment);
		} else setStartDate(startDate);
		if (endDate === undefined || optionClicked) {
			setEndDate(endDateMoment);
		} else setStartDate(startDate);

		setOptionClicked(false);
	}, [dateSelectedOption]);

	useEffect(() => {
		getGameRecords();
	}, [selectedOption, startDate, endDate]);

	useEffect(() => {
		if (isResetCurrentPage) {
			setSortOrder("");
		}
	}, [isResetCurrentPage]);

	useEffect(() => {
		if (sortOrder != "") {
			var gameHistoryData = gameHistoryData;
			var sortedGameHistoryData = orderBy(
				gameHistoryData,
				["amount"],
				sortOrder
			);

			setGameHistoryData(sortedGameHistoryData);
		}
	}, [sortOrder]);

	async function init() {
		setLoadingSearchData(true);
		try {
			var responseJson = await ApiEngine.get(
				`${ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT}?ignoreAccountProductStatus=true`
			);

			const productList = [];

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				responseJson[ApiKey._API_DATA_KEY]["products"].forEach(function (
					product
				) {
					productList.push({
						label: product.productName,
						value: product.productId,
					});
				});
			}

			setProducts(productList);
			setTotalProducts(productList.length);
		} catch (err) {
			_dispatch(showResponseMessage(false, err));
		}
		setLoadingSearchData(false);
	}

	async function getGameRecords(page, keyword) {
		setGameHistoryData([]);
		setLoadingFlag(true);
		try {
			page = page ? page : 1;
			var start = (page - 1) * 10;

			var apiUrl =
				ApiUrl._API_GET_GAME_RECORD_BY_MEMBER +
				"?startDate=" +
				moment(startDate).format("YYYY-MM-DD") +
				" 00:00:00" +
				"&endDate=" +
				moment(endDate).format("YYYY-MM-DD") +
				" 23:59:59" +
				"&v=" +
				Date.now() +
				"&GroupDateRequired=true" +
				"&TicketStatus=1";

			if (typeof selectedOption !== "undefined" && selectedOption > 0) {
				apiUrl += "&productId=" + selectedOption;
			}

			setValue(
				"productId",
				stringIsNullOrEmpty(queryProduct) ? null : queryProduct
			);

			apiUrl = apiUrl + "&start=" + start + "&count=10";

			setRecordUrl(apiUrl);

			var responseJson = await ApiEngine.get(apiUrl);

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				setGameHistoryData(
					responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY]
				);

				var totalCount = responseJson[ApiKey._API_DATA_KEY].totalCount;
				updatePageinationPageNum(Math.ceil(totalCount / 10));
			} else {
				_dispatch(
					showMessage(
						responseJson[ApiKey._API_SUCCESS_KEY],
						t(responseJson[ApiKey._API_MESSAGE_KEY])
					)
				);
			}
		} catch (err) {
			_dispatch(showResponseMessage(false, err));
		}
		setLoadingFlag(false);
		return;
	}

	useEffect(() => {
		if (chgOption) {
			setOptionClicked(true);
			setSelectedFilterType("");
			setchgOption(false);
		}
	}, [chgOption]);

	function updatePageinationPageNum(pageValue) {
		setPaginationPageNum((prevValue) => pageValue);
	}

	return (
		<div className="t3-downline-modal-body pt-0 mb-4" id="deposit-id">
			<h3 className="t3-h3 text-left mt-0">{t("BET_RECORD")}</h3>
			<div className="t3-statistics-dropdown-box mb-3">
				<div className="first">{t("STATISTICS")}</div>
				<div className="second">
					{!loadingSearchData ? (
						products && products.length > 0 ? (
							<select
								name="products"
								value={selectedOption}
								onChange={(e) => {
									setSelectedOption(e.target.value);
								}}
							>
								<option value="">{t("ALL")}</option>
								{products.map((item, index) => {
									return (
										<option key={index} value={item.value}>
											{item.label}
										</option>
									);
								})}
							</select>
						) : (
							<div className="no-data-text">{t("NO_DATA_FOUND")}</div>
						)
					) : (
						<Spinner
							animation="border"
							role="status"
							style={{
								verticalAlign: "middle",
								width: "1rem",
								height: "1rem",
							}}
						></Spinner>
					)}
				</div>
			</div>

			<DateRangeFilter
				// isOpen={isDateFilterOpen}
				startDate={startDate}
				endDate={endDate}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
				onSave={(start, end) => {
					setStartDate(start);
					setEndDate(end);
				}}
				options={true}
				initialType={selectedFilterType}
				// onClose={dateRangePickerOnClose}
				typeOnSelect={filterTypeOnSelect}
			/>
			<div className="t3-downline-table-body">
				<div className="t3-table-container">
					<table className="t3-custom-table">
						<thead>
							<tr>
								<th>{t("GAMES")}</th>
								<th>{t("DATE")}</th>
								<th>{t("BET_AMOUNT")}</th>
								<th>{t("NET_PROFIT")}</th>
							</tr>
						</thead>
						<tbody>
							{gameHistoryData && gameHistoryData.length != 0 ? (
								gameHistoryData.map((data, index) => {
									return (
										<tr key={index}>
											<td>{data["gameName"]}</td>
											<td className="downline-table-username">
												{moment(data["date"], "MM/DD/YYYY hh:mm:ss").format(
													"DD-MM-YYYY HH:mm:ss"
												)}
											</td>
											<td>
												{numberWithCurrencyFormat(
													parseFloat(data["amount"]),
													2,
													true
												)}
											</td>
											<td
												className={
													data["netProfit"] < 0
														? "text-customred1"
														: "text-customgreen"
												}
											>
												{numberWithCurrencyFormat(
													parseFloat(data["netProfit"]),
													2,
													true
												)}
											</td>
										</tr>
									);
								})
							) : loadingFlag ? (
									<tr>
										<td colSpan={4}>
											<div
												style={{
													textAlign: "center",
													marginTop: "25px",
													marginBottom: "20px",
												}}
											>
												<Spinner
													animation="border"
													role="status"
													size="sm"
													style={{
														verticalAlign: "middle",
														width: "2rem",
														height: "2rem",
													}}
												>
													<span className="sr-only">{t("LOADING")}...</span>
												</Spinner>
											</div>
										</td>
									</tr>
							) : (
								<tr>
									<td colSpan={4}>{t("NO_DATA_FOUND")}</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
			{paginationPageNum > 1 && (
				<PaginationBar
					totalPages={paginationPageNum}
					retrieveDataRecords={getGameRecords}
					isResetCurrentPage={isResetCurrentPage}
					setIsChangeTab={setIsResetCurrentPage}
				/>
			)}
		</div>
	);
};

export default GameHistory;
