import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../../util/Constant";
import { useTranslation } from "react-i18next";
import {
  setBusy,
  setIdle,
  showResponseMessage,
  setDownlineSearchText,
  setDownlineQuery,
  setPageSize,
  setY,
  setActiveTab,
  setDownline,
  setDownlineModal,
} from "../../../redux/AppAction.js";
import { useForm, Controller } from "react-hook-form";
import ApiEngine from "../../../util/ApiEngine.js";
import DynamicLoadTable from "../../../components/custom/DynamicLoadTable";
import StyledSelect from "../../../components/custom/StyledSelect";
import { Modal, ModalBody, Spinner, ModalHeader } from "reactstrap";
import classnames from "classnames";
import {
  createFormBody,
  createMultiPartFormBody,
  stringIsNullOrEmpty,
} from "../../../util/Util";
import SweetAlert from "react-bootstrap-sweetalert";
import ToggleButton from "react-toggle-button";
import { map } from "lodash";
import ContentHeader from "../../../components/contentheader/header";
import moment from "moment";
import DateRangeFilter from "components/custom/DateRangeFilter";
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";
import DownlineDetails from "../../template_1/home/DownlineDetails";
import DownLineSummary from "../../template_1/home/DownlineSummary";
import DownlineKPI from "../../template_1/home/DownlineKPI";

/// <summary>
/// Author: -
/// </summary>
const Downline = (props) => {
  var { t } = useTranslation(LanguageKey._PRIMARY);
  var _history = useHistory();
  var _dispatch = useDispatch();
  var _location = useLocation();
  const { register, handleSubmit, errors, setValue, watch, control, trigger } =
    useForm();

  const [monthOptions, setMonthOptions] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const [selectedGroup, setSelectedGroup] = useState(0);
  const [downlineShow, setDownlineShow] = useState(false);

  const downlineState = useSelector((state) => state.appState.downline);
  const { memberData, recentData } = useSelector(
    (state) => state.appState.downline
  );

  const [memberLMData, setMemberLMData] = useState([]);
  const dateRangePickerOnClose = () => {
    setIsDateFilterOpen(false);
  };
  const [startDate, setStartDate] = useState(
    moment().startOf("week").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("week").format("YYYY-MM-DD")
  );
  const [selectedDate, setSelectedDate] = useState("");
  const [queryProduct, setQueryProduct] = useState("");
  const [searchTrigger, setSearchTrigger] = useState(Date.now());
  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
  const [selectedFilterType, setSelectedFilterType] = useState(
    moment().startOf("week").format("DD-MM-YYYY") +
    t("TO") +
    moment().endOf("week").format("DD-MM-YYYY")
  );
  const filterTypeOnSelect = (value) => {
    setSelectedFilterType(value);
    };

  const isDownlineModal = useSelector((state) => state.appState.downlineModal);
  const [downlineDetailsActiveTab, setDownlineDetailsActiveTab] =
        useState(1);
  const [showDownlineDetailTab, setShowDownlineDetailTab] = useState(true);
  const scrollCategory = [
    {
        text: "DOWNLINE_SUMMARY",
        downlineTabId: 1,
    },
    {
        text: "DOWNLINES_KPIS",
        downlineTabId: 2,
    },
    ];

  useEffect(() => {
    // if (memberData === null && recentData === null) {
    //   _dispatch(setBusy());
    // } else {
    //   _dispatch(setIdle());
    // }
  }, [memberData, recentData]);

  const closeBtn = (
    <div
      className="close"
      onClick={() => _dispatch(setDownlineModal(!isDownlineModal))}
    >
      <VIcon icon={themeIcons.close} className="vicon" />
    </div>
  );

  return (
      <Modal
      id="t3-custom-modal"
      fade={false}
      className="modal-custom-fullscreen"
      contentClassName={"t3-custom-modal"}
      backdrop={"static"}
      isOpen={isDownlineModal}
      toggle={() => {
        // setShowModal(false);
        _dispatch(setDownlineModal(!isDownlineModal));
      }}
      fullscreen={"true"}
      //   centered
      >
        <ModalHeader close={closeBtn}>
          {t("DOWNLINE")}
        </ModalHeader>
        <ModalBody id="modalBody" className="modal-body-dynamic-table">
            {showDownlineDetailTab &&
                <div className="t3-downline-table-tab">
                    {
                        scrollCategory && scrollCategory.length &&
                        map(scrollCategory, (item, index) => {
                            return (
                                <button
                                    key={index}
                                    className={
                                        downlineDetailsActiveTab == item.downlineTabId
                                            ? "active"
                                            : ""
                                    }
                                    onClick={() => {
                                        setDownlineDetailsActiveTab(item.downlineTabId);
                                    }}
                                >
                                    <div

                                        style={{ cursor: "pointer" }}
                                    >
                                        {t(item.text)}
                                    </div>
                                </button>
                            );
                        })
                    }
                </div>
            }
            {downlineDetailsActiveTab == 1 && <DownLineSummary />}
            {downlineDetailsActiveTab == 2 && <DownlineKPI setShowDownlineDetailTab={setShowDownlineDetailTab} />}
        </ModalBody>
      </Modal>

  );
};

export default Downline;
