import { themeIcons } from "config/theme";
import Deposit from "pages/template_1/transaction/Deposit";
import Downline from "pages/template_1/home/Downline";
import WithdrawalMethods from "pages/template_1/transaction/WithdrawalMethods";
import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import i18n from "localization/i18n";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody } from "reactstrap";
import VIcon from "components/custom/Icon";
import { useDispatch, useSelector } from "react-redux";
import { setDownlineModal, setDepositTab } from "redux/AppAction";


const DownlineModal = (props) => {
    var { t } = useTranslation();
    const isDownlineModal = useSelector(
        (state) => state.appState.downlineModal
      );

    var _dispatch = useDispatch();

    useEffect(() => {
      }, [isDownlineModal]);


    return (
        <Modal
        id="t3-custom-modal-withdraw"
        fade={false}
        isOpen={isDownlineModal}
        backdrop={'static'}
        toggle={() => {
            // setShowModal(false);
            _dispatch(setDownlineModal(!isDownlineModal));

        }}
        //   centered
    >
        <ModalBody>
            <div className="t3-custom-modal-withdraw-container t3-modal-bg-dark">
                <div className="t3-cms-header">
                    <div className="first">
                        <div>
                            {t("DOWNLINES")}
                        </div>
                    </div>
                    <div className="second">
                        <div
                            className="t3-close-custom-modal"
                            onClick={() =>{
                                // setShowModal(!showModal)
                                _dispatch(setDownlineModal(!isDownlineModal));

                            }}
                        >
                            <VIcon icon={themeIcons.close} className="vicon" />
                        </div>
                    </div>
                </div>
                <Downline/>
            </div>
        </ModalBody>
    </Modal>
    )
}

export default DownlineModal;
