import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { Language, SessionKey } from "../util/Constant";

import common_en from "./en/common.json";
import common_kh from "./kh/common.json";
import common_cn from "./zh/common.json";
import common_vi from "./vi/common.json";
import common_id from "./id/common.json";
import common_pt from "./pt/common.json";
import common_bn from "./bn/common.json";
import common_zh_hk from "./zh-HK/common.json";
import common_ur from "./ur/common.json";
import common_ne from "./ne/common.json";

/// <summary>
/// Author: -
/// </summary>
const _resources = {};
_resources[Language._ENGLISH] = { common: common_en };
_resources[Language._KHMER] = { common: common_kh };
_resources[Language._CHINESE] = { common: common_cn };
_resources[Language._VIETNAMESE] = { common: common_vi };
_resources[Language._INDON] = { common: common_id };
_resources[Language._PORTUGUESE] = { common: common_pt };
_resources[Language._BENGALI] = { common: common_bn };
_resources[Language._TRADITIONAL_CHINESE] = { common: common_zh_hk };
_resources[Language._URDU] = { common: common_ur };
_resources[Language._NEPALI] = { common: common_ne };


if (window.ReactNativeWebView) {
	window.ReactNativeWebView.postMessage(
		JSON.stringify({
			action: "language",
			language: localStorage.getItem(SessionKey._LANGUAGE) || Language._ENGLISH,
		})
	);
}

i18n.use(initReactI18next).init({
	defaultNS: "common",
	debug: false,
	resources: _resources,
	lng: localStorage.getItem(SessionKey._LANGUAGE) || Language._ENGLISH,
	fallbackLng: [Language._ENGLISH, Language._KHMER],
	keySeparator: false,
	interpolation: {
		escapeValue: false, // react already safes from xss
	},
});

export default i18n;
